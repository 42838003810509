import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { FacebookShareButton, TwitterShareButton, FacebookIcon, TwitterIcon, EmailIcon } from 'react-share';
import ReactGA from 'react-ga';

import './share.scss';

let classNames = require('classnames');

@inject('routerStore', 'appStore')
@observer
export class Share extends Component {

    onClick(e, type) {
        ReactGA.event({
            category: 'advent',
            action: type,
            label: window.location.href,
            nonInteraction: true
        });

        return true;
    }

    getClassNames() {
        const { appStore, Modifier } = this.props;

        return classNames({
            'share': true,
            'share--footer': Modifier && Modifier === 'footer',
            'share--side': Modifier && Modifier === 'side',
            'share--modal': Modifier && Modifier === 'modal',
            'share--in': Modifier && Modifier === 'side' && appStore.state > 6
        });
    }

    render() {
        const { Settings } = this.props,
            shareUrl = window.location.href;

        return <aside className={this.getClassNames()}>
            <h2 className='share-title'>Share</h2>
            <ul className='share-menu'>
                <li className='share-item'>
                    <FacebookShareButton url={Settings.FacebookSocialUrl ? Settings.FacebookSocialUrl : shareUrl} beforeOnClick={(e) => this.onClick(e, 'shared_facebook')}>
                        <FacebookIcon size={40} round logoFillColor='#FFF6EA' iconBgStyle={{fill: 'transparent'}} />
                        <span className='sr-only'>
                            Share this site on Facebook
                        </span>
                    </FacebookShareButton>
                </li>
                <li className='share-item'>
                    <TwitterShareButton url={Settings.TwitterSocialUrl ? Settings.TwitterSocialUrl : shareUrl} beforeOnClick={(e) => this.onClick(e, 'shared_twitter')} title={Settings.TwitterSocialDescription}>
                        <TwitterIcon size={40} round logoFillColor='#FFF6EA' iconBgStyle={{fill: 'transparent'}} />
                        <span className='sr-only'>
                            Share this site on Twitter
                        </span>
                    </TwitterShareButton>
                </li>
                <li className='share-item'>
                    <a className="link-icon" href={`mailto:?subject=${Settings.EmailSubject}&body=${Settings.EmailBody}`} onClick={(e) => this.onClick(e, 'shared_email')} >
                        <EmailIcon size={40} round logoFillColor='#FFF6EA' iconBgStyle={{fill: 'transparent'}} />
                        <span className='sr-only'>
                            Share this site by Email
                        </span>
                    </a>
                </li>
            </ul>
        </aside>;
    }

}
