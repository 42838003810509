import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { observable } from 'mobx';
import VisibilitySensor from 'react-visibility-sensor';
import './door.scss';
import './door-backgrounds.scss';

let classNames = require('classnames');

@inject('routerStore', 'appStore')
@observer
export class Door extends Component {

    @observable isActive;
    @observable isOpen;
    _onTouch;

    // stored as a percentage of the image, and translated to pixels when needed
    // x from top, y from left (currently a 6 by 5 grid)
    doorStyles = {
        1: { x: 0, y: 0, location: [1, 0, 0, 1], align: 'right', size: '2squared', color: 'yellow', styles: { fontSize: '80px'}},
        2: { x: 3, y: 3, location: [0, 1, 0, 0], align: 'right', size: '2squared', color: 'light-blue', styles: { fontSize: '80px'}},
        3: { x: 1, y: 3, location: [0, 1, 0, 0], align: 'right', size: '2squared', color: 'light-blue', styles: { fontSize: '80px'}},
        4: { x: 4, y: 0, location: [0, 0, 1, 1], align: 'right', size: '2squared', color: 'dark-pink', styles: { fontSize: '80px'}},
        5: { x: 1, y: 2, location: [0, 0, 0, 0], align: 'right', size: '2down', color: 'yellow', styles: { marginLeft: 0}},
        6: { x: 1, y: 3, location: [0, 1, 0, 0], align: 'right', size: '2squared', color: 'light-pink', styles: { fontSize: '80px'}},
        7: { x: 3, y: 0, location: [0, 0, 0, 1], align: 'right', size: 'single', color: 'yellow', styles: {}},
        8: { x: 2, y: 0, location: [0, 0, 0, 1], align: 'right', size: '2across',  color: 'light-blue', styles: {}},
        9: { x: 0, y: 2, location: [1, 0, 0, 0], align: 'right', size: '2across',  color: 'light-blue', styles: { }},
        10: { x: 5, y: 3, location: [0, 1, 1, 0], align: 'right', size: '2across', color: 'yellow'},
        11: { x: 1, y: 2, location: [0, 0, 0, 0], align: 'right', size: '2down', color: 'light-pink'},
        12: { x: 5, y: 3, location: [0, 1, 1, 0], align: 'right', size: '2across',  color: 'light-pink', styles: { }},
        13: { x: 0, y: 0, location: [1, 0, 0, 1], align: 'right', size: '2squared', color: 'dark-pink', styles: { fontSize: '80px'} },
        14: { x: 3, y: 1, location: [0, 0, 0, 0], align: 'right', size: '2across', color: 'dark-pink'},
        15:  { x: 0, y: 4, location: [1, 1, 0, 0], align: 'right', size: 'single', color: 'yellow'},
        16: { x: 3, y: 1, location: [0, 0, 0, 0], align: 'right', size: '2across', color: 'dark-pink'},
        17: { x: 4, y: 2, location: [0, 0, 1, 0], align: 'right', size: '2down', color: 'yellow', styles: {}},
        18: { x: 0, y: 4, location: [1, 1, 0, 0], align: 'right', size: 'single', color: 'dark-pink', styles: { }},
        19: { x: 3, y: 0, location: [0, 0, 0, 1], align: 'right', size: 'single',  color: 'light-pink', styles: {}},
        20: { x: 0, y: 2, location: [1, 0, 0, 0], align: 'right', size: '2across', color: 'dark-pink'},
        21: { x: 3, y: 3, location: [0, 1, 0, 0], align: 'right', size: '2squared', color: 'light-pink', styles: { fontSize: '80px'}},
        22: { x: 2, y: 0, location: [0, 0, 0, 1], align: 'right', size: '2across', color: 'light-blue', styles: {}},
        23: { x: 4, y: 2, location: [0, 0, 1, 0], align: 'right', size: '2down', color: 'light-blue'},
        24: { x: 4, y: 0, location: [0, 0, 1, 1], align: 'right', size: '2squared', color: 'dark-pink', styles: { fontSize: '80px'}},
        25: { x: 0, y: 0, location: [0, 0, 1, 1], align: 'right', size: '2squared',  color: 'dark-pink', styles: { fontSize: '80px'}},
    };

    constructor(props) {
        super(props);
        const { Deal, routerStore } = props;

        this.setActive(routerStore.history.location, Deal);

        this.unlisten = routerStore.history.listen(location => {
            this.setActive(location, Deal);
        });
    }

    /**
     * Track the active state of a door so we can visually alter and animate the doors opening
     * @param location
     * @param Deal
     */
    setActive(location, Deal) {
        let url = location.pathname.split('/');
        this.isActive = url[url.length - 1] === Deal.URLSegment;
    }

    componentWillUnmount() {
        this.unlisten();

        document.removeEventListener('touch', this._onTouch);
    }

    /**
     * Get the position of the door in the scene
     * @param num
     * @param negative
     * @param width
     * @param height
     * @returns {*}
     */
    getPosition(num, negative = false, width = 0, height = 0) {
        let prefix = negative ? '-' : '',
            x,
            y;

        if (this.doorStyles.hasOwnProperty(num)) {
            x = this.doorStyles[num].x;
            y = this.doorStyles[num].y;
            return { top: this.toPixels(x, height, prefix), left: this.toPixels(y, width, prefix) };
        }

        return { top: '20px', left: '30px' };
    }

    /**
     * Turn a percentage width into pixels
     * @param percentage
     * @param total
     * @param prefix
     * @returns {string}
     */
    toPixels(percentage, total, prefix) {
        if (total === 0) {
            return prefix + percentage + 'px';
        }

        const result = percentage / 100 * total;
        return prefix + result + 'px';
    }

    /**
     * Change the object state, update the route, and push into local storage
     * @param e
     * @param url
     * @param id
     */
    onClick(e, url, id) {
        const { routerStore, appStore } = this.props;

        e.stopPropagation();
        e.preventDefault();

        if (appStore.state < 7) {
            appStore.appStarted();
            appStore.setAppState(7);
        }

        this.isOpen = true;

        setTimeout(function() {
            routerStore.history.push('/deal/' + url);
        }, 300);

        appStore.openDoor(id);
    }

    /**
     * Manually track if todays door is visible.
     * @param isVisible
     */
    inView(isVisible) {
        const {appStore, Deal} = this.props;

        if (Deal.IsToday) {
            appStore.todayInview = isVisible;
        }
    }

    render() {
        const { appStore, Deal, HasOpened, HasClaimed, sceneNum, prefix } = this.props;

        const classPrefix = prefix ? prefix : 'door';

        let currentDoorStyles = this.doorStyles[Deal.Date],
            classes = classNames({
            [`${classPrefix}`]: true,
            [`${classPrefix}--early`]: appStore.adventState === 'before',
            [`${classPrefix}--active`]: this.isActive,
            [`${classPrefix}--open`]: this.isOpen || HasOpened || this.isActive,
            [`${classPrefix}--today`]: Deal.IsToday,
            [`${classPrefix}--claimed`]: HasClaimed,
            [`${classPrefix}--larger`]: !appStore.isSlowLatency,
            [`${classPrefix}--${Deal.Date}`]: true,
            [`${classPrefix}--${currentDoorStyles.color}`]: currentDoorStyles.color,
            [`${classPrefix}--${currentDoorStyles.size}`]: currentDoorStyles.size,
            [`${classPrefix}--top${currentDoorStyles.x}`]: true,
            [`${classPrefix}--left${currentDoorStyles.y}`]: true,
            [`${classPrefix}--istop`]: currentDoorStyles.location[0],
            [`${classPrefix}--isright`]: currentDoorStyles.location[1],
            [`${classPrefix}--isbottom`]: currentDoorStyles.location[2],
            [`${classPrefix}--isleft`]: currentDoorStyles.location[3],
            [`${classPrefix}--align${currentDoorStyles.align}`]: true
        }),
        backgroundClasses = classNames({
            [`${classPrefix}-background`]: true,
            [`${classPrefix}-background--${sceneNum}`]: true,
        });

        return <VisibilitySensor resizeCheck delayedCall offset={{ left: 50 }} onChange={(e) => this.inView(e)}>
            <button
                ref={(door) => {
                    if (Deal.IsToday) {
                        appStore.todaysDoor = door;
                    }
                    this.doorRef = door;
                }}
                disabled={!Deal.URLSegment}
                className={classes}
                style={currentDoorStyles.styles}
                type='button'
                onClick={e => this.onClick(e, Deal.URLSegment, Deal.ID)}
            >
                <span className={`${classPrefix}-inner`}>
                    <span className={`${classPrefix}-shadow`} role='presentation' />
                    <span
                        className={`${classPrefix}-frame`}
                        style={currentDoorStyles.frameStyles}
                    >
                        <span
                            style={currentDoorStyles.backgroundStyles}
                            className={backgroundClasses}
                        />

                        <span className={`${classPrefix}-date`}>
                            {Deal.URLSegment &&
                            <span className='sr-only'>
                                Door
                            </span>}
                            {!Deal.URLSegment && <span className='sr-only'>
                                Door locked
                            </span>}
                            {Deal.Date}
                        </span>
                    </span>
                </span>
            </button>
        </VisibilitySensor>;
    }

}
