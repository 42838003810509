import React, {Component} from 'react';
import { inject, observer } from 'mobx-react';

import './artistslink.scss';

let classNames = require('classnames');

@inject('appStore', 'routerStore')
@observer
export class ArtistsLink extends Component {

    getClassNames(isFooter) {
        const { appStore } = this.props;

        return classNames({
            'artistslink': true,
            'artistslink--in': appStore.state >= 5,
            'artistslink--footer': isFooter,
            'artistslink--side': !isFooter
        });
    }

    render() {
        const { appStore, Modifier } = this.props,
            isFooter = Modifier === 'footer';

        return <aside className={this.getClassNames(isFooter)}>
            <a className='artistslink-button' href={appStore.artistsLink} target='_blank' rel='noopener'>

                <span className='sr-only'>Read more about the artist of this year's advent illustration</span>

                <svg viewBox='0 0 64 64' width='40' height='40' className='social-icon social-icon--artist'>
                    <g>
                        <circle cx='32' cy='32' r='31' fill='transparent'></circle>
                    </g>
                </svg>
            </a>
        </aside>;
    }

}
