import { createBrowserHistory } from 'history';
import {
    RouterStore as BaseRouterStore,
    syncHistoryWithStore
} from 'mobx-react-router';

export class RouterStore extends BaseRouterStore {

    static instance = false;

    constructor() {
        super();
        const history = createBrowserHistory();
        this.history = syncHistoryWithStore(history, this);
    }

    static getInstance() {
        return (
            RouterStore.instance || (RouterStore.instance = new RouterStore())
        );
    }

    closeModal(url = '/') {
        document.body.classList.add('modal-out');

        setTimeout(() => {
            document.body.classList.remove('modal-in', 'modal-has-share', 'modal-open');

            setTimeout(() => {
                document.body.classList.remove('modal-out');

                this.history.push(url);
            }, 300);
        }, 400);
    }

}

export default RouterStore;
