import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteHeadComponent } from '../shared/routehead.component';

@inject('appStore', 'routerStore', 'redeemStore')
@observer
export class RedeemResultComponent extends Component {

    getError(deal) {
        return <section key={deal.ID} className='redeem redeem-result redeem-result--error'>
            <div>
                <h1 className='redeem-resulttitle'>
                    Sorry
                </h1>
                <div className='redeem-resultcontent redeem-resultcontent--error'>
                    <p>
                        There has been an error with your request. Please try
                        again later, or manually reconcile this offer.
                    </p>
                </div>
                <button className='pure-button pure-button--default' onClick={e => this.onClick()}>
                     <span className='pure-button-inner'>
                        Close
                     </span>
                </button>
            </div>
        </section>;
    }

    getSuccess(deal) {
        return <section key={deal.ID} className='redeem redeem-result redeem-result--success'>
            <div>
                <h1 className='redeem-resulttitle'>
                    Thanks!
                </h1>
                <div className='redeem-resultcontent'>
                    <p>You have successfully redeemed this deal</p>
                </div>
                <button className='pure-button pure-button--default' onClick={e => this.onClick()}>
                     <span className='pure-button-inner'>
                        Close
                     </span>
                </button>
            </div>
        </section>;
    }

    onClick(e) {
        const { routerStore, appStore } = this.props;
        appStore.redeemState = false;
        routerStore.closeModal();
    }

    render() {
        const { redeemStore, hasError } = this.props;
        let title = '';

        if (redeemStore.deal) {
            title = hasError
                ? 'Redeem: Form submission failed'
                : 'Redeemed: ' + redeemStore.deal.Title;
        }

        return <div>
            {redeemStore.deal && !hasError && <div>
                <RouteHeadComponent
                    currentPage={redeemStore.deal}
                    currentTitle={title}
                />
                {this.getSuccess(redeemStore.deal)}
            </div>}

            {redeemStore.deal && hasError && <div>
                <RouteHeadComponent
                    currentPage={redeemStore.deal}
                    currentTitle={title}
                />
                {this.getError(redeemStore.deal)}
            </div>}
        </div>;
    }

}
