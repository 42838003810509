import {action, observable} from 'mobx';
import gql from 'graphql-tag';
import { AppStore } from '../_app/app.store';
import { RouterStore } from '../router.store';

const ClaimDealMutation = gql`
        mutation ClaimDealMutation(
            $URLSegment: String!
            $FirstName: String!
            $LastName: String!
            $Email: String!
            $Terms: String!
        ) {
            claimDeal(
                URLSegment: $URLSegment
                FirstName: $FirstName
                LastName: $LastName
                Email: $Email
                Terms: $Terms
            ) {
                ID
                SuccessTitle
                SuccessSubtitle
                SuccessMessage
                SuccessImageURL
            }
        }`;

export class ClaimStore {

    appStore; // AppStore;
    Link; // String;
    Form; // null;
    routerStore; // RouterStore;

    @observable successMessage = false;
    @observable successTitle = false;
    @observable successSubtitle = false;
    @observable successImageURL = false;

    @observable errorMessage = false;
    @observable loading = false;
    @observable formOpen = false;
    @observable currentContext = false;


    constructor() {
        this.appStore = AppStore.getInstance();
        this.routerStore = RouterStore.getInstance();
        this.success = false;
    }

    @action setContext(value) {
        this.currentContext = value;
    }

    @action openForm(value) {
        this.formOpen = value;

        if (value) {
            this.routerStore.history.push(
                '/claim/' + this.Link
            );
        } else {
            this.routerStore.history.push(
                '/deal/' + this.Link
            );
        }
    }

    async submit(form) {
        this.loading = true;
        const result = await this.appStore.apolloClient
            .mutate({
                mutation: ClaimDealMutation,
                variables: {
                    URLSegment: this.Link,
                    FirstName: form.FirstName,
                    LastName: form.LastName,
                    Email: form.Email,
                    Terms: form.Terms,
                },
                fetchPolicy: 'no-cache'
            })
            .catch(result => {
                const errors = result.graphQLErrors.map(error => error.message);
                this.errorMessage = errors.join(', ');
                this.loading = false;
            });

        if (result && result.data && result.data.claimDeal) {
            if (result.data.claimDeal.ID) {
                this.appStore.claimDeal(result.data.claimDeal.ID);
                this.appStore.updateUserData(form);
                this.successTitle = result.data.claimDeal.SuccessTitle;
                this.successSubtitle = result.data.claimDeal.SuccessSubtitle;
                this.successMessage = result.data.claimDeal.SuccessMessage;
                this.successImageURL= result.data.claimDeal.SuccessImageURL;

                this.routerStore.history.push(
                    '/claim/' + this.Link + '/success'
                );
            } else {
                this.routerStore.history.push('/claim/' + this.Link + '/error');
            }
            this.loading = false;
        }
    }

}
