import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Door } from '../door/door';
import './scene.scss';

@inject('routerStore', 'appStore')
@observer
export class Scene extends Component {

    @observable currentEvent = 'resizing';

    constructor(props) {
        super(props);
        let self = this;

        // slow down our resizer. It miscalculating without this check.
        window.addEventListener('resize', function () {
            if (!self.currentEvent) {
                self.currentEvent = 'resizing';
            }
        }, false);

        window.addEventListener('orientationchange', function () {
            self.currentEvent = 'orientation';
        }, false);
    }

    /**
     * Returns the deals for this scene
     * @param deals
     * @param scene
     */
    getDeals(deals, scene) {
        return deals.filter(deal => (scene.indexOf(deal.Date) !== -1 && !deal.LinkOnly));
    }

    render() {
        const {scene, sceneNum, appStore, deals} = this.props,
            opened = appStore.doorOpened.slice(),
            claimed = appStore.dealClaimed.slice();

        this.sceneNum = sceneNum;

        return <div className='scene-deals'>
            {this.getDeals(deals, scene).map(deal =>
                <Door
                    sceneNum={sceneNum}
                    className='test'
                    key={deal.ID}
                    Deal={deal}
                    HasOpened={opened.find(item => parseInt(item, 10) === parseInt(deal.ID, 10))}
                    HasClaimed={claimed.find(item => parseInt(item, 10) === parseInt(deal.ID, 10))}
                />
            )}
        </div>;
    }

}
