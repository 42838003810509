import { observable } from 'mobx';
import gql from 'graphql-tag';
import { AppStore } from '../_app/app.store';

const ContentQuery = gql`
    query ContentQuery($Link: String!) {
        readPages(Link: $Link) {
            ID
            Title
            Content
            Link
        }
    }
`;

export class ContentStore {

    appStore; // AppStore;
    Link; // String;

    @observable page = [];
    @observable loading = false;

    constructor() {
        this.appStore = AppStore.getInstance();
    }

    async initializeContent() {
        this.loading = true;
        const result = await this.appStore.apolloClient
            .query({
                query: ContentQuery,
                variables: {
                    Link: this.Link
                },
                fetchPolicy: 'network-only'
            })
            .catch(result => {
                this.appStore.serverError = true;
                this.loading = false;
            });

        if (
            result &&
            result.data.readPages &&
            result.data.readPages.length > 0
        ) {
            this.page = result.data.readPages[0];
            this.loading = false;
        } else {
            this.page = false;
            this.loading = false;
        }
    }

}
