import React, { Component } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export class CheckboxIconChecked extends Component {

    render() {
        return <SvgIcon viewBox='0 0 24 24'>
            <path d='M3,3 L21,3 L21,21 L3,21 L3,3 Z M10,17.42 L19,8.42 L17.59,7 L10,14.59 L6.41,11.01 L5,12.42 L10,17.42 Z' fill='#52392e' />
        </SvgIcon>;
    }

}
