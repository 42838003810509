import '@babel/polyfill';
import './shared/_sass-globals/style.scss';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Redirect, Switch } from 'react-router';
import { ModalRoute } from 'react-router-modal';
import { Provider } from 'mobx-react';
import { create } from 'mobx-persist';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import localForage from 'localforage';
import ReactGA from 'react-ga';

import { App } from './_app/app';
import { AppStore } from './_app/app.store';
import { RouterStore } from './router.store';

import { HomeComponent } from './_home/home.component';
import { ContentProvider } from './_content/content.provider';
import { DealProvider } from './_deal/deal.provider';
import { ClaimProvider } from './_claim/claim.provider';
import { RedeemProvider } from './_redeem/redeem.provider';

// disabling service worker because caching issues
// import register from './registerServiceWorker';
// register();

localForage.ready().catch(function() {
    /* so that webpack sees the rejected promise as handled */
    console.log('No storage!');
});

// Set up local storage
localForage.config({
    name: 'advent2023',
    version: 1.0,
    storeName: 'keyvaluepairs' // Should be alphanumeric, with underscores.
});

const appStore = AppStore.getInstance(),
    routerStore = RouterStore.getInstance(),
    rootStores = {
        appStore,
        routerStore
    },
    hydrate = create({
        jsonify: true,
        storage: localForage
    }),
    theme = createTheme({
        palette: {
            type: 'light', // Switching the dark mode on is a single property value change.
            primary: {
                main: '#52392e',
            },
            error: {
                main: '#ef5252',
            },
            secondary: {
                main: '#52392e',
                darker: '#15052B'
            },
        },
        typography: {
            'fontFamily': '"montserrat-light", sans-serif',
            'color': '#38393B'
        },
        overrides: {
            MuiInputBase: {
                root: {
                    color: '#52392e',
                },
            },
            MuiTypography: {
                root: {
                    color: '#52392e',
                },
                body1: {
                    fontSize: '15px'
                }
            },
        }
    });

hydrate('doorOpened', appStore);
hydrate('dealClaimed', appStore);
hydrate('userData', appStore);
hydrate('dealRedeemed', appStore);
hydrate('started', appStore);

ReactDOM.render(
    <Router history={routerStore.history}>
        <Provider {...rootStores}>
            <MuiThemeProvider theme={theme}>
                <App className='app'>
                    <Switch location={location}>
                        <Route exact path='/' component={HomeComponent} />
                        <Route exact path='/index.html' component={HomeComponent} />
                        <Route exact path='/home' component={HomeComponent} />

                        <ModalRoute exact path='/deal/:a' component={ClaimProvider} />
                        <ModalRoute exact path='/olddeal/:a' component={DealProvider} />

                        <ModalRoute exact path='/claim/:a' component={ClaimProvider} />
                        <ModalRoute exact path='/claim/:a/:result' component={ClaimProvider} />

                        <ModalRoute exact path='/redeem/:token' component={RedeemProvider} />
                        <ModalRoute exact path='/redeem/:token/:result' component={RedeemProvider} />

                        <ModalRoute exact path='/:a' component={ContentProvider} />
                        <ModalRoute exact path='/:a/:b' component={ContentProvider} />
                        <ModalRoute exact path='/:a/:b/:c' component={ContentProvider} />
                        <ModalRoute exact path='/:a/:b/:c/:d' component={ContentProvider} />

                        <Route path='/404' component={ContentProvider} />
                        <Route path='/500' component={ContentProvider} />
                        <Redirect to='/404' />
                    </Switch>
                </App>
            </MuiThemeProvider>
        </Provider>
    </Router>,
    document.getElementById('root')
);

routerStore.history.listen(location => {
    // Fire event tracking to GTM\
    let url = window.location.pathname + window.location.search + window.location.hash;
    ReactGA.pageview(url);
});
