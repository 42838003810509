import React, { Component } from 'react';
import { inject, Provider } from 'mobx-react';
import { AppComponent } from './app.component';
import { ModalContainer } from 'react-router-modal';
import { Tracking } from '../shared/tracking/tracking';
import ReactGA from 'react-ga';

import './app.scss';

let classNames = require('classnames');

@inject('appStore', 'routerStore')
export class App extends Component {


    constructor(props) {
        super(props);
        const { appStore, routerStore } = props;

        appStore.Link = '/';
        appStore.initializeContent();

        // if redeeming set redeemState on appStore
        if (routerStore.location.pathname.split('/')[1] === 'redeem') {
            appStore.redeemState = true;
        }
    }

    renderDevTool() {
        if (process.env.NODE_ENV !== 'production') {
            const DevTools = require('mobx-react-devtools').default;
            return <DevTools />;
        }
    }

    /**
     * We need to be able to animate out, so handle our own modal closing
     */
    closeModal() {
        const { routerStore } = this.props;
        routerStore.closeModal();
    }

    onClick(e) {
        const { appStore } = this.props;

        // track start screen close
        if (appStore.state === 5) {
            appStore.setAppState(6); // close the start screen if present

            ReactGA.event({
                category: 'advent',
                action: 'intro_clicked',
                label: 'ok_got_it',
                nonInteraction: true
            });

            return; // return so we don't close any modals
        }

        // outbound link tracking
        if ((e.target.tagName && e.target.tagName.toLowerCase() === 'a') && e.target.hostname !== window.location.hostname) {
            ReactGA.event({
                category: 'advent',
                action: 'button_clicked',
                label: 'external_link',
                nonInteraction: true,
                transport: 'beacon'
            });

            return;
        }

        // close footer on click outside
        if (appStore.footerOpen === true && !e.target.closest('.footer')) {
            appStore.footerOpen = false;
            return;
        }

        if (e.target.classList && e.target.classList.contains('modal-wrapper')) {
            appStore.redeemState = false;
            this.closeModal();
        }
    }

    static getModalClasses(path) {
        const rootPath = path.split('/')[1],
            modalModifier = {
                deal: 'modal modal--small',
                redeem: 'modal modal--small',
                claim: 'modal modal--large'
            };

        if (modalModifier.hasOwnProperty(rootPath)) {
            return modalModifier[rootPath];
        }

        return 'modal modal--large';
    }

    static getModalContainerClasses() {
        return 'modal-container modal-effect-fade';
    }

    render() {
        const { appStore, routerStore } = this.props,
            modalClasses = App.getModalClasses(routerStore.location.pathname),
            classes = classNames({
                'app': true,
                'app--larger': !appStore.isSlowLatency
            });

        return <div className={classes} onClick={e => this.onClick(e)}>
            <ModalContainer
                bodyModalOpenClassName='modal-open'
                containerClassName={App.getModalContainerClasses()}
                backdropClassName='modal-shadow'
                modalClassName={modalClasses}
            />
            <Provider appStore={appStore}>
                <AppComponent />
            </Provider>

            <Tracking />
            {this.props.children}
            {this.renderDevTool()}
        </div>;
    }

}
