import { observable, action, computed } from 'mobx';
import gql from 'graphql-tag';
import { persist } from 'mobx-persist';
import ApolloClient from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

// get all deals for rendering
const HomeQuery = gql`
        query HomeQuery($Link: String!) {
            readPages(Link: $Link) {
                ID
                Title
                Content
                Link
                TermsLink
                ArtistsLink
                AdventState
                AdventIndefinite
                AdventOverLink
                SiteTitle
                SocialTitle
                SocialDescription
                FacebookSocialTitle
                FacebookSocialUrl
                FacebookSocialDescription
                TwitterSocialTitle
                TwitterSocialDescription
                TwitterSocialHandle
                TwitterSocialUrl
                EmailSubject
                EmailBody
                SocialImage
                AdventCurrentDay
                StartDate
                SponsorLink
                SponsorText
                SponsorLogo
                SponsorLogoTitle
            }
        }
    `,
    DealQuery = gql`
        query DealQuery {
            readDeals {
                ID
                Title
                TitleNice
                Details
                Date
                ImageURL
                AnimationURL
                URLSegment
                IsToday
                DateNice
                DateType
                AlwaysOn
                LinkOnly
                DealAction
                ThemeColour
                TextShade
                SuccessImageURL
                PrevDeal {
                    ID
                    URLSegment
                }
                NextDeal {
                    ID
                    URLSegment
                }
            }
        }
    `,
    SettingsQuery = gql`
        query SettingsQuery {
            readSettings {
                GACode
                SiteTitle
                SocialTitle
                SocialDescription
                FacebookSocialTitle
                FacebookSocialUrl
                FacebookSocialDescription
                TwitterSocialTitle
                TwitterSocialDescription
                TwitterSocialHandle
                TwitterSocialUrl
                EmailSubject
                EmailBody
                SocialImage
            }
        }
    `;

export class AppStore {

    static instance;// AppStore;

    @persist('list') @observable doorOpened = [];
    @persist('list') @observable dealClaimed = [];
    @persist('list') @observable dealRedeemed = [];
    @persist('list') @observable started = []; // an array of one boolean
    @persist('object') @observable userData = {};

    @observable deals = [];
    @observable settings;
    @observable page;
    @observable termsLink;
    @observable sponsorLink;
    @observable sponsorText;
    @observable sponsorLogo;
    @observable sponsorLogoTitle;
    @observable serverError = false;
    @observable adventState = false;
    @observable adventIndefinite = false;
    @observable adventOverLink = false;
    @observable adventCurrentDay = false;
    @observable startDate = false;
    @observable loadTo = 0;
    @observable goToScene = 0; // set when attempting to change the current slider from  outside the slider
    @observable currentScene = 0; // set after on change event from slider
    @observable footerOpen = false;
    @observable isDragging = false;
    @observable averageLatency = 0;
    @observable isSlowLatency = true; // helper to detect if this connection is slow
    @observable isSmallScreen = false;
    @observable isAndriod = false;
    @observable screenSize;
    @observable currentDeal = false;
    @observable scrollPosition = 0;

    @observable imagesLoaded = false;

    // 0: preload. Wait for the loading screen to fade in
    // 1: loading, load artwork and deals
    // 2: loaded, show start button, or if there is an active route, fadeout (postload) and goto 7
    // 3: ready, wait for user to click enter
    // 4. fading out loadscreen
    // 5. startscreen, id user hasnt clicked 'got it' before, show this screen (disabled)
    // 6. startscreen out, wait for start screen to fade out (disabled)
    // 7: started, show app
    @observable state = 7;
    @observable redeemState = false;

    @observable titleImage = {};
    @observable sceneImages = [];

    // required because the Lottie globe is smaller than the lottie canvas
    @observable sceneSize = {};

    @observable responsiveAssets = [];

    // for images/svgs that aren't scene images, and don't need to serve responsive versions
    @observable assets = [
        { src: '/svg/brush.svg' },
        { src: '/svg/logo.svg' },
        { src: '/svg/wellingtonnz-logo.svg' }
    ];

    @observable itemsToLoad;

    ratioLottieAssets = 6;

    defaultTitle = 'Wellington Advent Calendar';

    loadFrom = 0;
    next = 1;
    todaysDoor;
    todaysPosition = {};
    startShown = true;
    numScenes = this.sceneImages ? this.sceneImages.length : 0;

    sceneDoors = [
        [13,9,18,22,5,6,7,16,2,24,17,12],
        [1,20,15,8,11,3,19,14,21,4,23,10]
    ];

    Link; // String;

    @computed get stageWidth() {
        let result = 0,
            i;

        for (i = 0; i < this.sceneImages.length; i = i + 1) {
            result += this.sceneImages[i].w;
        }

        return result;
    }

    @action setScrollPosition(value) {
       this.scrollPosition = value;
    }

    /**
     * Used to trigger a change in the slider to any given slide number
     * The actual change happens in AppComponent
     * @param sceneNum
     */
    @action triggerScene(sceneNum) {
        this.goToScene = sceneNum;
    }

    @action setDragging(state) {
        this.isDragging = state;
    }

    @action setItemsToLoad() {
        this.itemsToLoad = this.assets.length + this.responsiveAssets.length + this.ratioLottieAssets + 2; // number of items that need loading = num images + num api calls
    }

    /**
     * Used to trigger a change in the slider to any given slide number
     * The actual change happens in AppComponent
     * @param deal
     */
    @action setDeal(deal) {
        this.currentDeal = deal;
    }


    /**
     * The user has scrolled, so update our index to whichever
     * scene we have marked as coming next
     */
    @action setNextScene() {
        this.setCurrentScene(this.next);
    }

    /**
     * Update our scene index
     * @param sceneNum
     */
    @action setCurrentScene(sceneNum) {
        this.currentScene = sceneNum;
    }

    @action setBreakpoints() {
        const wasSmallScreen = this.isSmallScreen;
        this.isSmallScreen = window.innerWidth < 768; // less than medium breakpoint
        this.screenSize = window.innerWidth;

         // if we are moving between desktop and mobile, reset assets
        if (wasSmallScreen !== this.isSmallScreen) {
            this.setAssets();
        }

        this.setScrollPosition(0);
    }
    @action updateLottieLoaded() {
        this.updateLoadingProgress(this.ratioLottieAssets);
    }

    @action openDoor(id) {
        this.doorOpened.push(id);
    }

    @action claimDeal(id) {
        this.dealClaimed.push(id);
    }

    @action redeemDeal(id) {
        this.dealRedeemed.push(id);
    }

    @action appStarted() {
        this.started.push(true);
    }

    @action updateUserData(form) {
        this.userData = form;
    }

    @action setAppState(n, timeout = 0) {
        if (n < this.state) {
            return; // safeguard to prevent going backwards
        }

        if (this.adventCurrentDay !== false && this.adventCurrentDay < 1) {
            return;
        }

        if (timeout > 0) {
            setTimeout(() => {
                this.state = n;
            }, timeout);
        } else {
            this.state = n;
        }
    }

    @action updateLoadingProgress(n = 1, forceLoad = false) {
        this.loadFrom = this.loadTo;

        if (forceLoad) {
            this.loadTo = 100;
            return;
        }

        let loaded = this.loadTo + Math.ceil((n / this.itemsToLoad) * 100);
        if (loaded > 100) {
            loaded = 100;
        }

        this.loadTo = loaded;
    }


    @action setIsAndroid() {
        const userAgent = navigator.userAgent.toLowerCase();

        this.isAndriod = userAgent.indexOf("android") > -1;
    }

    constructor() {
        this.setBreakpoints();
        this.setIsAndroid();

        this.setAssets();

        this.setItemsToLoad();
    }

    setAssets() {
        if (this.isSmallScreen) {
            this.setMobileAssets();
            return;
        }

        this.setDesktopAssets();
    }

    /**
     * Check what our latency is and serve a larger asset payload, if we can
     */
    setDesktopAssets() {
        this.titleImage = {src: '/images/start.png', w: 2478, h: 1972 }; // update to full loading image

        this.responsiveAssets = [
            // load static assets here
        ];

        this.sceneImages = [
            // load static assets here
        ];

        this.sceneSize = {
            w: 3559.5,
            h: 2278.5
        };
    }

    /**
     * Check what our latency is and serve a larger asset payload, if we can
     */
    setMobileAssets() {
        this.titleImage = {
            src: '/images/start_sm.png',
            w: 2442,
            h: 1563
        };

        this.responsiveAssets = [
            { src: '/images/background_sm.jpg' },
            { src: '/images/start_sm.png' },
        ];

        this.sceneImages = [
            {
                src: '/images/background_sm.jpg',
                w: 3051, // 60% (max 78%)
                h: 1953
            }
        ];

        // required because the Lottie scene can be smaller than the lottie canvas
        this.sceneSize = {
            w: 3051,
            h: 1953
        };
    }

    /**
     * Helper method to retrieve the scene for the current deal
     * @param dealNum
     * @returns {*}
     */
    getSceneForDeal(dealNum) {
        if (!dealNum) {
            return false;
        }

        let i;

        for (i = 0; i < this.sceneDoors.length; i = i + 1) {
            if (this.sceneDoors[i].indexOf(dealNum) !== -1) {
                return i;
            }
        }

        return false;
    }

    /**
     * Debug for app state
     */
    getAppStateDebug() {
        let state = '';

        switch (this.state) {
            case 0:
                state = 'preload';
                break;
            case 1:
                state = 'loading';
                break;
            case 2:
                state = 'loaded';
                break;
            case 3:
                state = 'ready';
                break;
            case 4:
                state = 'loaded screen out';
                break;
            case 5:
                state = 'start screen';
                break;
            case 6:
                state = 'start screen out';
                break;
            case 7:
                state = 'started';
                break;
            default:
                state = 'unknown';
                break;
        }

        return state + ' / ' + this.state;
    }

    getApiUri() {
        return '//api.' + window.location.hostname + '/api/';
    }

    apolloClient = new ApolloClient({
        link: new HttpLink({ uri: this.getApiUri() }),
        cache: new InMemoryCache().restore(window.__APOLLO_STATE__)
    });

    static getInstance() {
        return AppStore.instance || (AppStore.instance = new AppStore());
    }



    async initializeSettings() {
        const result = await this.apolloClient
            .query({
                query: SettingsQuery,
                variables: {
                    Link: this.Link
                },
                fetchPolicy: 'cache-first'
            })
            .catch(e => {
                this.serverError = true;
                return false;
            });

        if (
            result &&
            result.data.readSettings &&
            result.data.readSettings.length > 0
        ) {
            this.settings = result.data.readSettings[0];
        }
    }

    async initializeContent() {
        const result = await this.apolloClient.query({
            query: HomeQuery,
            variables: {
                Link: this.Link
            },
            fetchPolicy: 'network-only'
        }).catch(e => {
            this.serverError = true;
            return false;
        });

        if (
            result &&
            result.data.readPages &&
            result.data.readPages.length > 0
        ) {
            this.page = result.data.readPages[0];
            this.termsLink = this.page.TermsLink;
            this.artistsLink = this.page.ArtistsLink;
            this.adventState = this.page.AdventState;
            this.adventIndefinite = this.page.AdventIndefinite;
            this.adventOverLink = this.page.AdventOverLink;
            this.adventCurrentDay = this.page.AdventCurrentDay;
            this.startDate = this.page.StartDate;
            this.sponsorLink = this.page.SponsorLink;
            this.sponsorText = this.page.SponsorText;
            this.sponsorLogo = this.page.SponsorLogo;
            this.sponsorLogoTitle = this.page.SponsorLogoTitle;
        }
    }

    async initializeDeals() {
        const result = await this.apolloClient.query({
            query: DealQuery,
            variables: {
                Link: false
            },
            fetchPolicy: 'network-only'
        }).catch(e => {
            this.serverError = true;
            return false;
        });

        // if (result.data && result.data.readDeals && result.data.readDeals.length > 0) {
        if (result.data && result.data.readDeals) {
            this.deals = result.data.readDeals;
        } else {
            this.deals = false;
        }
    }

}
