import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteHeadComponent } from '../shared/routehead.component';
import './content.scss';

@inject('appStore', 'contentStore', 'routerStore')
@observer
export class ContentComponent extends Component {

    getContent(page) {
        return <section key={page.ID} className='content text--left'>
            <div className='content-container container'>
                <h1 className='content-title'>
                    {page.Title}
                </h1>
                <div dangerouslySetInnerHTML={{ __html: page.Content }} />
                <button className='pure-button pure-button--default' onClick={e => this.onClick(e)}>
                    <span className='pure-button-inner'>
                        Close
                    </span>
                </button>
            </div>
        </section>;
    }

    get404() {
        return <section className='content content--error content--error404'>
            <div className='content-container container'>
                <h1 className='content-title'>
                    Page not found
                </h1>
                <div className='content-content'>
                    <p>
                        Sorry, this page was not found
                    </p>
                </div>
                <button className='pure-button pure-button--default' onClick={e => this.onClick(e)}>
                  <span className='pure-button-inner'>
                        Close
                  </span>
                </button>
            </div>
        </section>;
    }

    get500() {
        return <section className='content content--error content--error404'>
            <div className='content-container container'>
                <h1 className='content-title'>
                    Server Error
                </h1>
                <div className='content-content'>
                    <p>
                        Sorry, there was a problem handling your request. Please try
                        again later.
                    </p>
                </div>
                <button className='pure-button pure-button--default' onClick={e => this.onClick(e)}>
                  <span className='pure-button-inner'>
                    Close
                  </span>
                </button>
            </div>
        </section>;
    }

    onClick(e) {
        const { routerStore } = this.props;
        routerStore.closeModal();
    }

    render() {
        const { contentStore, routerStore } = this.props;

        let is500 = routerStore.location.pathname === '/500',
            is400 = !is500 && !contentStore.page;

        return <div>
            {contentStore.page &&
            <div>
                <RouteHeadComponent currentTitle={contentStore.page.Title} showModalClose={true} currentPage={contentStore.page} />
                {this.getContent(contentStore.page)}
            </div>}
            {is500 &&
            <div>
                <RouteHeadComponent currentTitle='Server Error' showModalClose={true} currentPage={false} />
                {this.get500()}
            </div>}
            {is400 &&
            <div>
                <RouteHeadComponent currentTitle='Not Found' showModalClose={true} currentPage={false} />
                {this.get404()}
            </div>}
        </div>;
    }

}
