import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteHeadComponent } from '../shared/routehead.component';

@inject('appStore', 'routerStore')
@observer
export class HomeComponent extends Component {

    constructor(props) {
        super(props);
        const { appStore, routerStore  } = props;

        if (appStore.serverError) {
            routerStore.history.push('/500');
        }
    }

    /**
     * This component is only around to handle the head state and any other root route only data.
     * Most information needed for the root route in this app is available on all routes, so is loaded via
     * app.js and the app store
     * @returns {XML}
     */
    render() {
        const {appStore} = this.props;

        return <div>
            {appStore.page && <RouteHeadComponent currentTitle={appStore.page.SiteTitle} showModalClose={false} currentPage={appStore.page} />}
        </div>;
    }

}
