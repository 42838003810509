import React, {Component} from 'react';
import { inject, observer } from 'mobx-react';
import {observable} from 'mobx';
import { Share } from '../share/share';
import { ArtistsLink } from "../artistslink/artistslink";
import './footer.scss';


let classNames = require('classnames');

@inject('appStore', 'routerStore')
@observer
export class Footer extends Component {

    // control item being hovered
    @observable hover = -1;

    onSceneClick(e, scene) {
        const { appStore } = this.props;
        appStore.triggerScene(scene);
    }

    onSceneMouseEnter(e, i) {
        this.hover = i;
    }

    onSceneMouseLeave(e, i) {
        this.hover = -1;
    }

    onToggleClick(e) {
        const { appStore } = this.props;
        appStore.footerOpen = !appStore.footerOpen;
    }

    getSceneClassName(i) {
        const { appStore } = this.props;

        return classNames({
            'footercontrols-button': true,
            'footercontrols-button--active': appStore.currentScene === i,
            'footercontrols-button--hoverremainder': this.hover > -1 && (i < (this.hover - 1) || i > (this.hover + 1)),
            'footercontrols-button--hovernext': this.hover > -1 && this.hover + 1 === i,
            'footercontrols-button--hovernextnext': this.hover > -1 && this.hover + 2 === i,
            'footercontrols-button--hoverprev': this.hover > -1 && this.hover - 1 === i,
            'footercontrols-button--hoverprevprev': this.hover > -1 && this.hover - 2 === i
        });
    }

    getToggleClassName() {
        const { appStore } = this.props;
        return classNames({
            'toggle': true,
            'toggle--active': appStore.footerOpen === true
        });
    }

    getClassNames() {
        const { appStore } = this.props;

        return classNames({
            'footer': true,
            'footer--in': appStore.state >= 5 || appStore.redeemState,
            'footer--open': appStore.footerOpen === true,
            'footer--noshadow': appStore.state < 6,
            'footer--hidecontrols': appStore.state < 6 || appStore.redeemState
        });
    }

    render() {
        const { appStore } = this.props;
        const hasArtistLink = appStore.artistsLink && appStore.artistsLink !== '/';
        const { sponsorLink, sponsorLogo, sponsorLogoTitle, sponsorText } = appStore;

        return <footer className={this.getClassNames()}>
            <div className='footer-inner'>
                <div className="footer-wnz footer-section">
                    <h2 className='footer-title'>Brought to you by</h2>
                    <span className='footer-logo footer-logo--main'>
                        <a className='footer-wellingtonnzlogo' href='https://www.wellingtonnz.com' target='_blank' rel='noopener'>WellingtonNZ.com</a>
                    </span>
                </div>
                <div className='footer-icons footer-section'>
                    { hasArtistLink && <div className='footer-about'>
                        <h2 className='footer-title'>About the artist</h2>
                        <ArtistsLink Modifier='footer'/>
                    </div>}
                </div>
                <div className="footer-sponsor footer-section">
                    {(sponsorLogo || sponsorText) && <h2 className='footer-title'>Proudly supporting</h2>}
                    {(sponsorLogo && sponsorLink) && <a href={sponsorLink} className="footer-sponsor-link"><img src={sponsorLogo} alt={sponsorLogoTitle} className="footer-sponsor-logo" /></a>}
                    <div className="footer-sponsor-text" dangerouslySetInnerHTML={{
                        __html: sponsorText
                    }}></div>
                </div>
            </div>
        </footer>;
    }

}
