import { Form } from 'mobx-react-form';
import validatorjs from 'validatorjs';

export default class ClaimForm extends Form {

    URLSegment = '';
    Store = null;

    static locations = [
        'Wellington City',
        'Wellington Region',
        'Rest of North Island',
        'Rest of South Island'
    ];

    // Checkboxes don't seem to bind properly, so we'll store the values manually for now
    checkboxes = {
        'Terms': false
    }

    setURL(url) {
        this.URLSegment = url;
    }

    setStore(store) {
        this.Store = store;
    }

    /**
     * Return a plugins object using the `validatorjs` package
     * to enable Declarative Validation Rules
    */
    plugins() {
        return {
            dvr: validatorjs
        };
    }

    options() {
        return {
            validateOnInit: true,
            validateOnChange: true,
            showErrors: true
        };
    }

    /**
     * Form field rules
    */
    setup() {
        return {
            fields: [
                {
                    name: 'FirstName',
                    label: 'First Name',
                    placeholder: '',
                    rules: 'required|string|between:1,250'
                },
                {
                    name: 'LastName',
                    label: 'Last Name',
                    placeholder: '',
                    rules: 'required|string|between:1,250'
                },
                {
                    name: 'Email',
                    label: 'Email',
                    placeholder: '',
                    rules: 'required|email|string',
                    value: '',
                    hooks: {
                        onChange: (field) => {
                            field.value = field.value.trim()
                        },
                    }
                },
                {
                    name: 'Terms',
                    type: 'checkbox',
                    label: 'Terms and Conditions',
                    rules: 'required' // note, this isn't working for checkboxes so we will check this manually
                },
            ]
        };
    }

    /**
     * Event Hooks
     */
    hooks() {
        return {
            onSubmit(form) {
                if (!this.checkboxes.Terms) {
                    // manually mark form and terms field invalid
                    form.$('Terms').invalidate('This field is required');
                    form.invalidate(
                        "Oops, looks like you've missed something, have another go."
                    );

                    return false;
                }
            },
            onSuccess(form) {
                let result = form.values();
                result.Terms = this.checkboxes.Terms ? 'on' : '';
                this.Store.submit(result);
            },
            onError(form) {
                form.invalidate(
                    "Oops, looks like you've missed something, have another go."
                );
            }
        };
    }

}
