import React, { Component } from 'react';
import { observable, autorun, intercept } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Loading } from '../shared/loading/loading';
import { Footer } from '../shared/footer/footer';
import { Stage } from '../shared/stage/stage';
import * as ReactGA from 'react-ga';

@inject('appStore', 'routerStore')
@observer
export class AppComponent extends Component {

    @observable hasMouse = false;
    preloadDeals = false;

    hasSeenClosed; // only redirect to the closed route once per refresh
    imagesLoaded = false;
    _onResize;
    _onTouch;
    _onGestureStart;

    /**
     * Get rid of all bound intercepts and autoruns
     */
    componentWillUnmount() {
        this.disposeAutorun();
        this.disposeSettings();
        this.disposeState();
        window.removeEventListener('resize', this._onResize, false);
        document.removeEventListener('touch', this._onTouch);
        document.removeEventListener('gesturestart', this._onGestureStart);
    }


    constructor(props) {
        super(props);
        const { appStore } = props,
            debug = false;//process.env.NODE_ENV !== 'production';

        this._onResize = this.onResize.bind(this);
        window.addEventListener('resize', this._onResize);

        this._onTouch = this.onTouch.bind(this);
        document.addEventListener('touch', this._onTouch);

        this._onGestureStart = this.onTouch.bind(this);
        document.addEventListener('gesturestart', this._onGestureStart);

        // stagger data requests
        appStore.initializeSettings().then(() => {
            appStore.updateLoadingProgress();

            appStore.initializeDeals().then(() => {
                appStore.updateLoadingProgress();
            });
        });

        // Manage the advent is over/closed state
        this.disposeAutorun = autorun(() => {
            if ((appStore.adventState === 'shut' || appStore.adventState === 'over') && !this.hasSeenClosed && appStore.adventOverLink) {
                this.showAdventOver();
            }
        });

        // manage analytics events
        this.disposeSettings = intercept(appStore, 'settings', (change) => {
            if (change.newValue.GACode) {
                ReactGA.initialize(change.newValue.GACode, {debug: debug});
                let url = window.location.pathname + window.location.search + window.location.hash;
                ReactGA.pageview(url);
            }
            return change;
        });

        this.disposeState = intercept(appStore, 'state', (change) => {
            if (change.newValue > 1) {
                this.imagesLoaded = true;
            }

            return change;
        });
    }

    /**
     * prevent zoom from killing the touch action
     * @param e
     */
    onTouch(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    onResize() {
        const { appStore } = this.props;

        appStore.setBreakpoints();
    }

    /**
     * The advent is over, so we need to direct the user to our message
     * when they land on a closed route
     */
    showAdventOver() {
        const { routerStore, appStore } = this.props,
            pathname = routerStore.location.pathname,
            path = this.getRoutePath(),
            routeRedirects = ['/', 'deal', 'claim']; // only redirect action urls, let users reach auxiliary urls first time.

        if (pathname === appStore.adventOverLink) {
            this.hasSeenClosed = true; // prevent issues if the user reloads on this page
            return;
        }

        if (appStore.adventState === 'shut') {
            routeRedirects.push('redeem'); // only redirect redeem urls if the advent is shut
        }

        if (routeRedirects.indexOf(path) === -1) {
            return;
        }

        this.hasSeenClosed = true;
        routerStore.history.push(appStore.adventOverLink);
    }

    getRoutePath() {
        const { routerStore } = this.props,
            pathname = routerStore.location.pathname;

        return pathname.length > 1 ? pathname.split('/')[1] : pathname;
    }

    isRedeem() {
        return this.getRoutePath() === 'redeem';
    }

    /**
     * Preload the large images so they are ready when the app loads
     */
    getArtwork() {
        return (
            <div className='artworkrenderer'>
                {this.preloadDeals.map((item, i) => {
                    return <div key={i}>
                        {item.ImageURL && <img
                            src={item.ImageURL}
                            alt=''
                            onLoad={() => this.dealAssetLoaded(i, item, 'dealImage')}
                        />}
                        {item.SuccessImageURL && <img
                            src={item.SuccessImageURL}
                            alt=''
                            onLoad={() => this.dealAssetLoaded(i, item, 'successImage')}
                        /> }

                        {item.AnimationURL && <video
                            muted='muted'
                            preload='preload'
                            height='4px'
                            onLoad={() => this.dealAssetLoaded(i, item, 'animation')}
                        >
                            <source
                                src={item.AnimationURL} />
                        </video>}

                    </div>;
                })}
            </div>
        );
    }

    /**
     * Callback for when an image loads
     * Set each as loaded and increment the app loaded status
     * @param i
     * @param item
     * @param type
     */
    dealAssetLoaded(i, item, type) {
        const { appStore } = this.props;
        if (type === 'dealImage' && !item.dealImgLoaded) {
            this.preloadDeals[i].dealImgLoaded = true;
            appStore.updateLoadingProgress();
        }

        if (type === 'successImage' && !item.successImageLoaded) {
            this.preloadDeals[i].successImageLoaded = true;
            appStore.updateLoadingProgress();
        }

        if (type === 'animation' && !item.animationLoaded) {
            this.preloadDeals[i].animationLoaded = true;
            appStore.updateLoadingProgress();
        }

        if (i === this.preloadDeals.length - 1) {
            appStore.imagesLoaded = true;
        }
    }

    /**
     * Callback for when an image loads
     * Set each as loaded and increment the app loaded status
     * @param i
     * @param item
     */
    assetLoaded(i, item) {
        const { appStore } = this.props;
        if (!item.loaded) {
            appStore.assets[i].loaded = true;
            appStore.updateLoadingProgress();
        }
    }

    render() {
        const { appStore } = this.props,
            early = !appStore.adventState, //!appStore.adventState || appStore.adventState === 'before',
            renderImages = !appStore.imagesLoaded;

        this.preloadDeals = appStore.deals ? appStore.deals.slice() : false;

        return <div className='app-wrapper' style={{'top': - appStore.scrollPosition}}>
            {!early && <>
                {(renderImages && this.preloadDeals.length > 0) && this.getArtwork()}
                <div className='app-component'>
                    <Stage renderImages={renderImages} isRedeem={this.isRedeem()} />
                    <Footer />
                </div>
            </>
            }
        </div>;
    }

}
