import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Helmet from 'react-helmet/es/Helmet'; // React-helmet handles document head modifications (title, meta data etc)

@inject('appStore', 'routerStore')
@observer
export class RouteHeadComponent extends Component {

    componentDidMount() {
        setTimeout(function() {
            document.body.classList.add('modal-in');
        }, 300);
    }

    onClose(e) {
        const { routerStore, appStore } = this.props;
        appStore.redeemState = false;
        routerStore.closeModal('/');
    }

    getClose() {
        return <div className='modal-closewrap'>
            <button
                className='modal-close'
                type='submit'
                onClick={e => this.onClose(e)}>
                <span className='sr-only'>Close</span>
            </button>
        </div>;
    }

    getTitle() {
        const { appStore, currentPage, currentTitle } = this.props;

        let title = appStore.defaultTitle;

        if (currentTitle) {
            return currentTitle;
        }

        if (currentPage) {
            title = currentPage.TitleNice ? currentPage.TitleNice : (currentTitle || currentPage.Title);

            if(appStore.page) {
                title = title + ' | ' + appStore.page.SiteTitle;
            }

            return title;
        }

        return title;
    }

    render() {
        const { closeModal, currentPage, showModalClose } = this.props;

        this.closeModal = closeModal;

        let title = this.getTitle();

        return <>
            {currentPage && <Helmet>
                <title>{title}</title>
            </Helmet>}
            {showModalClose && this.getClose()}
        </>;
    }

}
