import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteHeadComponent } from '../shared/routehead.component';
import { Share } from '../shared/share/share';

let classNames = require('classnames');

@inject('appStore', 'routerStore', 'claimStore')
@observer
export class ClaimResultComponent extends Component {

    getError(deal) {
        return <section key={deal.ID} className='claim-formgroup claim-result claim-result--error'>
            <div>
                <h1 className='claim-resulttitle'>
                    Sorry
                </h1>
                <div className='claim-resultcontent claim-resultcontent--error'>
                    <p>
                        There has been an error with your request.
                        Please try again later.
                    </p>
                </div>
            </div>
        </section>;
    }

    getSuccess(deal) {
        const { appStore, claimStore } = this.props,
            titleClasses = classNames({
                'claim-resulttitle': true,
                'claim-resulttitle--long': claimStore.successTitle && claimStore.successTitle.length > 6
            }),
            defaultContent = 'You\'ve successfully claimed this deal.';

        document.body.classList.add('modal-has-share');

        return <section key={deal.ID} className='claim-formgroup claim-result claim-result--success'>
            <div>
                {claimStore.successTitle && <h1
                    className={titleClasses}
                    dangerouslySetInnerHTML={{
                        __html: claimStore.successTitle
                    }}
                />}

                {!claimStore.successTitle && <h1
                    className={titleClasses}>
                    Nice ! </h1>}

                {claimStore.successSubtitle && <h2
                    className='claim-resultsubtitle'
                    dangerouslySetInnerHTML={{
                        __html: claimStore.successSubtitle
                    }}
                />}

                {claimStore.successMessage && <div
                    className='claim-resultcontent'
                    dangerouslySetInnerHTML={{
                        __html: claimStore.successMessage
                    }}
                />}

                {(!claimStore.successSubtitle && !claimStore.successMessage) && <h2
                    className='claim-resultsubtitle'
                    dangerouslySetInnerHTML={{
                        __html: defaultContent
                    }}
                />}

                {claimStore.successImageURL && <img
                    className='claim-resultimage' src={claimStore.successImageURL} alt='' />}

            </div>
        </section>;
    }

    onClick() {
        const { claimStore } = this.props;
        claimStore.openForm(false);
    }

    render() {
        const { hasError, deal } = this.props;

        let title;

        if (deal) {
            title = hasError ? 'Claim: Form submission failed' : 'Deal claimed: ' + deal.Title;
        }

        return <>
            {deal && !hasError &&
                <div className='claim-resultinner'>
                    <RouteHeadComponent
                        currentPage={deal}
                        currentTitle={title}
                    />
                    {this.getSuccess(deal)}
                </div>}

            {deal && hasError &&
                <div className='claim-resultinner'>
                    <RouteHeadComponent
                        currentPage={deal}
                        currentTitle={title}
                    />
                    {this.getError(deal)}
                </div>}
        </>;
    }

}
