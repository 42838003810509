import React, { Component } from 'react';
import { inject, observer, Provider } from 'mobx-react';
import {autorun, intercept, observable} from 'mobx';
import { DealComponent } from './deal.component';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import ModalLoading from '../shared/modalloading/modalloading';
import './deal.scss';

@inject('appStore', 'routerStore')
@observer
export class DealProvider extends Component {

    @observable deal;

    constructor(props) {
        super(props);
        const { appStore } = props,
        self = this;

        this.disposeDeals = intercept(appStore, 'deals', (change) => {
            if (change.newValue) {
                this.getDeal(change.newValue);
            }
            return change;
        });

        if (appStore.deals.length) {
            this.getDeal(appStore.deals);
        }

        this.disposeDealTheme = autorun(() => {
            if (appStore.currentDeal) {
                self.addModalClasses(props);
            }
        });

        this.disposeDealTheme = () => void 0;
    }

    addModalClasses(props) {
        const { appStore } = props;

        let theme = 'modaltheme--' + appStore.currentDeal.ThemeColour,
            textTheme = 'modaltheme--text' + appStore.currentDeal.TextShade;

        this.removeTheme();
        document.body.classList.add(theme);
        document.body.classList.add(textTheme);
    }

    componentWillUnmount() {
        this.disposeDeals();
        this.disposeDealTheme();
    }

    removeTheme() {
        document.body.classList.forEach(className => {
            if (className.startsWith('modaltheme--')) {
                document.body.classList.remove(className);
            }
        });
    }

    getDeal(deals) {
        const { routerStore, appStore } = this.props,
            URLSegment = this.getURL(this.props.match.params);

        deals = deals.slice();

        if (deals.length) {
            this.deal = this.getDealByUrlSegment(deals, URLSegment);
            appStore.setDeal(this.deal);
            if (!this.deal) {
                routerStore.history.push('/404');
            }
        }
    }

    /**
     * get a deal by its URLSegment
     */
    getDealByUrlSegment(deals, URLSegment) {
        return deals.find(
            item => item.URLSegment === URLSegment
        );
    }

    /**
     * Construct the query url from our router params
     * eg :id1/:id2/:id3:id4
     */
    getURL(params) {
        let key,
            url = '',
            count = 0;

        for (key in params) {
            if (params.hasOwnProperty(key)) {
                url += count > 0 ? '/' : '';
                url += params[key];
                count++;
            }
        }

        return url;
    }

    getComponent() {
        if (!this.deal) {
            return <ModalLoading key='dealloading' />;
        }

        return <DealComponent key='deal' deal={this.deal} />;
    }

    render() {
        return <div className='modal-wrapper'>
            <div className='modal-content'>
                <div className='modal-inner'>
                    <Provider>
                        <div>
                            <ReactCSSTransitionReplace
                                transitionName='modal-animate'
                                transitionEnterTimeout={600}
                                transitionLeaveTimeout={300}
                                component='div'
                                childComponent='div'>
                                {this.getComponent()}
                            </ReactCSSTransitionReplace>
                        </div>
                    </Provider>
                </div>
            </div>
        </div>;
    }

}
