import { observable } from 'mobx';
import gql from 'graphql-tag';
import { AppStore } from '../_app/app.store';
import { RouterStore } from '../router.store';

const DealQuery = gql`
    query DealQuery($ID: Int, $Token: String) {
        readDeals(ID: $ID, Token: $Token) {
            ID
            Title
            Details
            Date
            URLSegment
            DateNice
            DateType
            AlwaysOn
            LinkOnly
        }
    }`,
    RedeemDealMutation = gql`
    mutation RedeemDealMutation(
        $ID: Int!
        $Token: String
        $RedeemCode: String
    ) {
        redeemDeal(ID: $ID, Token: $Token, RedeemCode: $RedeemCode) {
            ID
        }
    }`;

export class RedeemStore {

    appStore;
    routerStore;
    DealID = 0;
    Token = null;
    Link; // String;

    @observable errorMessage = false;
    @observable deal;
    @observable loading = false;

    constructor() {
        this.appStore = AppStore.getInstance();
        this.routerStore = RouterStore.getInstance();
        this.success = false;
    }

    async submit(form) {
        this.loading = true;
        const result = await this.appStore.apolloClient
            .mutate({
                mutation: RedeemDealMutation,
                variables: {
                    ID: this.DealID,
                    Token: this.Token,
                    RedeemCode: form.RedeemCode
                },
                fetchPolicy: 'no-cache'
            })
            .catch(result => {
                console.log(result);
                const errors = result.graphQLErrors.map(error => error.message);
                this.errorMessage = errors.join(', ');
                this.loading = false;
            });

        if (result && result.data && result.data.redeemDeal) {
            if (result.data.redeemDeal.ID) {
                this.appStore.redeemDeal(result.data.redeemDeal.ID);
                this.routerStore.history.push(
                    '/redeem/' + this.Link + '/success'
                );
            } else {
                this.routerStore.history.push(
                    '/redeem/' + this.Link + '/error'
                );
            }
            this.loading = false;
        }
    }

    async initializeDeal() {
        this.loading = true;
        const result = await this.appStore.apolloClient
            .query({
                query: DealQuery,
                variables: {
                    ID: this.DealID,
                    Token: this.Token
                },
                fetchPolicy: 'network-only'
            }).catch(result => {
                const errors = result.graphQLErrors.map(error => error.message);
                this.errorMessage = errors.join(', ');
                this.loading = false;
            });

        if (result && result.data.readDeals && result.data.readDeals.length > 0) {
            this.deal = result.data.readDeals[0];
            this.loading = false;
        } else {
            this.deal = false;
            this.loading = false;
        }
    }

}
