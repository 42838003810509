import { Form } from 'mobx-react-form';
import validatorjs from 'validatorjs';

export default class RedeemForm extends Form {

    URLSegment = '';
    Store = null;

    setURL(url) {
        this.URLSegment = url;
    }

    setStore(store) {
        this.Store = store;
    }

    /**
     * Return a plugins object using the `validatorjs` package
     * to enable Declarative Validation Rules
    */
    plugins() {
        return {
            dvr: validatorjs
        };
    }

    options() {
        return {
            validateOnInit: true,
            validateOnChange: true,
            showErrors: true
        };
    }

    /**
     * Form field rules
    */
    setup() {
        return {
            fields: [
                {
                    name: 'RedeemCode',
                    label: 'Enter your redeem code',
                    placeholder: 'Redeem code',
                    rules: 'required|string|between:2,25'
                }
            ]
        };
    }

    /**
     * Event Hooks
     */
    hooks() {
        return {
            onSuccess(form) {
                this.Store.submit(form.values());
            },
            onError(form) {
                form.invalidate(
                    "Oops, looks like you've missed something, have another go."
                );
            }
        };
    }

}
