import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { DealComponent } from '../_deal/deal.component';

import ReactModal from 'react-modal';
import {ClaimComponent} from "./claim.component";

ReactModal.setAppElement('#root');

@inject('appStore', 'routerStore', 'claimStore', 'claimForm')
@observer
export class ClaimDealComponent extends Component {

    getContent(deal) {
        const { claimStore, result } = this.props,
            isDeal = claimStore.currentContext === 'deal';

        return <section key={deal.ID}>
            <div className='pure-g'>
                <div className='pure-u-1'>
                    <div className='claim-deal'>
                        {this.getClose()}
                        <DealComponent deal={deal} store={claimStore} IsNested={!isDeal} />
                    </div>
                </div>
            </div>
            <ClaimComponent deal={deal} result={result} claimStore={claimStore} />
        </section>;
    }

    onClick(e, url) {
        const { store } = this.props;
        store.setContext('claim');
        store.openForm(true);
    }

    getClose() {
        return <div className='modal-closewrap'>
            <button
            className='modal-close'
            type='submit'
            onClick={e => this.onClose(e)}>
                <span className='sr-only'>Close</span>
            </button>
        </div>;
    }

    onClose(e) {
        const { routerStore, appStore } = this.props;
        appStore.redeemState = false;
        routerStore.closeModal('/');
    }

    render() {
        const { claimForm, deal } = this.props;

        return <div className='claim'>
            {deal && claimForm && <div>
                {this.getContent(deal)}
            </div>}
        </div>;
    }
}
