import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { FormControl, FormHelperText, Input, InputLabel } from '@material-ui/core';
import { RouteHeadComponent } from '../shared/routehead.component';
import ReactModal from "react-modal";

@inject('appStore', 'routerStore', 'redeemStore', 'redeemForm')
@observer
export class RedeemComponent extends Component {

    redeemForm = false;

    getInput(fieldName) {
        const { redeemForm } = this.props,
            hasError = redeemForm.errors()[fieldName] !== null;

        return <FormControl
            fullWidth
            className='field field--large'
            error={hasError}
        >
            <InputLabel
                htmlFor={redeemForm.$(fieldName).id}
                className='field-textlabel'>
                {redeemForm.$(fieldName).label}
            </InputLabel>

            <Input
                error={hasError}
                className='field-text'
                {...redeemForm.$(fieldName).bind()}
            />

            {redeemForm.errors()[fieldName] &&
            <FormHelperText>
                {redeemForm.errors()[fieldName]}
            </FormHelperText>}
        </FormControl>;
    }

    getForm() {
        const { redeemForm } = this.props;

        return <form onSubmit={redeemForm.onSubmit} className=''>
            <div className='form-fields'>
                {this.getInput('RedeemCode')}
            </div>

            <div className='form-actions'>
                <button
                    type='submit'
                    className='pure-button pure-button--default'
                    onClick={e => redeemForm.onSubmit(e)}>
                    <span className='pure-button-inner'>
                        Redeem Deal
                    </span>
                </button>
            </div>
        </form>;
    }

    getContent(deal) {
        const { redeemStore, redeemForm } = this.props;

        return <div key={deal.ID} className='redeem'>
            <div className='form-group'>
                <h1 className='redeem-title'>
                    {deal.Title}
                </h1>
                <h2 className='redeem-subtitle'>
                    Redeem voucher
                </h2>
                {this.getForm()}

                {(redeemForm.error || redeemStore.errorMessage) && <div className='alert-wrapper'>
                    {redeemForm.error && <p className='alert alert--error'>
                        {redeemForm.error}
                    </p>}
                    {redeemStore.errorMessage && <p className='alert alert--error'>
                        {redeemStore.errorMessage}
                    </p>}
                </div>}
            </div>
        </div>;
    }

    getClose() {
        return <div className='modal-closewrap'>
            <button
                className='modal-close'
                type='submit'
                onClick={e => this.onClose(e)}>
                <span className='sr-only'>Close</span>
            </button>
        </div>;
    }

    onClose(e) {
        const { routerStore, appStore } = this.props;
        appStore.redeemState = false;
        routerStore.closeModal('/');
    }

    getError() {
        const { redeemStore } = this.props;

        return <div className='redeem'>
            <div className='redeem-textonly text--center'>

                <h1 className='redeem-title'>
                    Redeem: Voucher error
                </h1>
                <p>
                    {redeemStore.errorMessage}
                </p>
            </div>
        </div>;
    }

    render() {
        const { appStore, redeemStore, redeemForm } = this.props;

        let title = redeemStore.deal ? 'Redeem: ' + redeemStore.deal.Title : 'Redeem: Deal not found';

        return <div>
            {redeemStore.deal && redeemForm && <div>
                <RouteHeadComponent
                    currentPage={redeemStore.deal}
                    currentTitle={title}
                    showModalClose={false}
                />
                {this.getClose()}
                {this.getContent(redeemStore.deal)}
            </div>}
            {!redeemStore.deal && redeemStore.errorMessage && <div>
                <RouteHeadComponent currentTitle={title} />
                {this.getClose()}
                {this.getError()}
            </div>}
        </div>;
    }

}
