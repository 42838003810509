import React, {Component} from 'react';

export class Tracking extends Component {

    render() {
        const axel = Math.random() + '',
            a = axel * 10000000000000,
            bxel = Math.random() + '',
            b = bxel * 10000000000000;

        return <div>
            <iframe
                src={`https://4255141.fls.doubleclick.net/activityi;src=4255141;type=Retar0;cat=wreda0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=${a}?`}
                width='1'
                height='1'
                frameBorder='0'
                style={{display: 'none'}} />
            <iframe
                src={`https://4255141.fls.doubleclick.net/activityi;src=4255141;type=conv0;cat=wreda0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;ord=${b}?`}
                width='1'
                height='1'
                frameBorder='0'
                style={{display: 'none'}} />
        </div>;
    }

}
