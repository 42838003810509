import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { RouteHeadComponent } from '../shared/routehead.component';
import { useSwipeable } from "react-swipeable";
let classNames = require('classnames');

const DealContent = (props) => {
    const { deal, hasCTA, self } = props;

    if (!self) {
        return;
    }

    const nextDeal = self.getNext(deal),
        wrapClasses = classNames({
            'deal-wrap': true,
            [`text--${deal.TextShade}`]: deal.TextShade,
            'text--light': !deal.TextShade
        }),
        contentClasses = classNames({
            'deal-content': true,
            'deal-content--locked': deal.IsLocked
        }),
        imgClasses = classNames({
            'deal-img': !deal.AnimationURL,
            'deal-img--locked': deal.IsLocked,
            'deal-video': deal.AnimationURL
        }), handlers = useSwipeable({
            onSwipedLeft: () => { nextDeal && self.handleNavEvent(nextDeal.URLSegment, nextDeal.ID) },
            onSwipedRight: () => { deal.PrevDeal && self.handleNavEvent(deal.PrevDeal.URLSegment, deal.PrevDeal.ID) },
            preventDefaultTouchmoveEvent: true,
            trackMouse: true
        });

        return <section className={wrapClasses} key={deal.ID} {...handlers}>
            <div className='deal-card'>
                {!deal.LinkOnly && self.getTitleNav(deal)}
                <div className={imgClasses}>

                    {(!deal.AnimationURL && deal.ImageURL) && <img
                        src={deal.ImageURL}
                        title={deal.Title}
                        alt=''
                    />}
                    {deal.AnimationURL && <video
                        loop
                        autoPlay
                        muted='muted'
                        preload='preload'
                        playsInline
                        disableremoteplayback='true'
                        poster={deal.ImageURL ? deal.ImageURL : false}
                        height='400px'
                        controls={false}
                    >
                        <source
                            src={deal.AnimationURL} />
                        {deal.ImageURL && <img
                            src={deal.ImageURL}
                            title={deal.Title}
                            alt=''
                        />}
                        Your browser doesn't support HTML5 video tag.
                    </video>}
                    {deal.IsLocked && <div className='deal-lockedtext'>
                        <span>
                            {deal.LockedText}
                        </span>
                    </div>}
                </div>
                <div className={contentClasses}>
                    <h2 className='deal-title'>{deal.Title}</h2>
                    <p className='deal-details' dangerouslySetInnerHTML={{__html: deal.Details}} />
                </div>
            </div>
            {hasCTA && <div className='deal-actions'>
                <button className='pure-button pure-button--default' onClick={(e) => self.onClick(e, deal.URLSegment)} >
                    <span className='pure-button-inner'>
                        Email me this treat
                    </span>
                </button>
            </div>}
        </section>;
    };

@inject('appStore', 'routerStore')
@observer
export class DealComponent extends Component {
    /**
     * Get rid of all bound intercepts and autoruns
     */
    componentWillUnmount() {
        document.removeEventListener('keydown', this._onKeyDown);
    }

    constructor(props) {
        super(props);
        this._onKeyDown = this.handleKeyDown.bind(this);
        document.addEventListener('keydown', this._onKeyDown);
    }

    getNiceDate(deal) {
        let dateType = deal.DateType,
            dateNice = deal.DateNice,
            dateList = dateNice ? dateNice.split(' '): false ;

        return <h1 className='deal-date'>
            {dateType === 'date' ? (
                <>
                    <span className='deal-dateday'>{dateList[0]}</span>
                    <span className='deal-datenumber'>
                        {dateList[1]}
                    </span>
                    <span className='deal-datemonth'>{dateList[2]}</span>
                </>
            ) : (
                <span className='deal-datenumber'>
                    {dateNice}
                </span>
            )}
        </h1>
    }

    /**
     * Change the object state, update the route, and push into local storage
     * @param e
     * @param url
     * @param id
     */
    onNavClick(e, url, id) {
        e.stopPropagation();
        e.preventDefault();

        this.handleNavEvent(url, id);
    }

    handleKeyDown(e) {
        const { deal } = this.props,
            nextDeal = this.getNext(deal);

        if (e.keyCode === 37 && deal.PrevDeal) {
            this.handleNavEvent(deal.PrevDeal.URLSegment, deal.PrevDeal.ID);
        } else if (e.keyCode === 39 && nextDeal) {
            this.handleNavEvent(nextDeal.URLSegment, nextDeal.ID);
        }
    }

    handleNavEvent(url, id) {
        const { routerStore, appStore } = this.props;

        if (!url || !id) {
            return false;
        }

        setTimeout(function() {
            routerStore.history.push('/deal/' + url);
        }, 300);

        appStore.openDoor(id);
    }

    onClick(e, url) {
        const { store } = this.props;
        store.setContext('claim');
        store.openForm(true);
    }

    getNext(deal) {
        let nextDeal = deal.NextDeal;

        if (!nextDeal && !deal.IsLocked && deal.Date < 24) {
            nextDeal = {
                URLSegment: deal.Date + 1,
                ID: 'empty-' + deal.Date + 1
            }
        }

        return nextDeal;
    }

    getTitleNav(deal) {
        const nextDeal = this.getNext(deal);

        return <header className='deal-header'>
            <button
                className='deal-nav deal-nav--prev'
                disabled={!deal.PrevDeal}
                onClick={e => deal.PrevDeal && this.onNavClick(e, deal.PrevDeal.URLSegment, deal.PrevDeal.ID)}>

                <span className='icon icon--left' />
                <span className='sr-only'>
                    Previous
                </span>
            </button>
            {this.getNiceDate(deal)}
             <button
                 className='deal-nav deal-nav--next'
                 disabled={!nextDeal}
                 onClick={e => nextDeal && this.onNavClick(e, nextDeal.URLSegment, nextDeal.ID)}>

                   <span className='icon icon--right' />
                   <span className='sr-only'>
                    Next
                </span>
            </button>
        </header>;
    }

    getContent (deal, hasCTA) {
        return <DealContent deal={deal} hasCTA={hasCTA} self={this} />;
    }

    render() {
        const { appStore, deal, IsNested } = this.props,
            hasCTA = appStore.adventState === 'open' && !deal.IsLocked;


        return <div className='deal'>
            {deal && <>
                {!IsNested && <RouteHeadComponent
                    currentPage={deal}
                    showModalClose={false}
                /> }
                {this.getContent(deal, hasCTA)}
            </>
            }
        </div>;
    }

}
