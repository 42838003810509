import React, { Component } from 'react';
import * as ReactDOM from 'react-dom';
import { intercept, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Scene } from '../scene/scene';
import Modernizr from 'modernizr';
import scrollIntoView from 'scroll-into-view';
import createPanZoom from 'panzoom/dist/panzoom';
import './stage.scss';
import './stagedoor.scss';
import {Door} from '../door/door';

let Shake = require('shake.js');
let classNames = require('classnames');
@inject('routerStore', 'appStore')
@observer
export class Stage extends Component {

    @observable hasMouse = false;
    @observable currentKey = false; // we use alt to enable scrollwheel zoom
    @observable bounds;
    @observable isMoving = false; // used to pause the animation when zooming/panning to avoid glitchyness and speed issues
    @observable zoomTime = false; // used to calculate zoom end
    @observable animateGlobe;
    @observable slideStarted = false;

    sceneRefs = []; // tracks a reference to each rendered scene, so we can scrollTo them
    zoomer = false;
    deals = false;

    _onTouch;
    _onGestureStart;

    doorOrder = [
        13,
        9,
        18,
        22,
        5,
        6,
        7,
        16,
        2,
        24,
        17,
        12,
        1,
        20,
        15,
        8,
        11,
        3,
        19,
        14,
        21,
        4,
        23,
        10,
        25,
    ];

    /**
     * Get rid of all bound intercepts and autoruns
     */
    componentWillUnmount() {
        this.disposeGoTo();
        this.disposeState();
        document.removeEventListener('gesturestart', this._onGestureStart);
        document.removeEventListener('touch', this._onTouch);
    }

    constructor(props) {
        super(props);
        const { appStore } = props;

        this._onTouch = this.onTouch.bind(this);
        this._onGestureStart = this.onTouch.bind(this);

        document.addEventListener('gesturestart', this._onGestureStart);
        document.addEventListener('touch', this._onTouch);

        this.disposeGoTo = intercept(appStore, 'goToScene', (change) => {
            if (this.zoomer) {
                this.attachZoomer();
            }

            return this.handleGoToScene(change);
        });

        this.disposeState = intercept(appStore, 'state', (change) => {
            let oldBrowser = Modernizr.flexboxtweener;

            // IE11 doesn't trigger resize first time around, so fix
            if (oldBrowser && change.newValue > 3) {
                setTimeout(function() {
                    let event = document.createEvent('Event');
                    event.initEvent('resize', true, false, window, 0);
                    window.dispatchEvent(event);
                }, 1000);
            }

            if (this.slideStarted || change.newValue < 3) { // change this value to 7 if you don't want the stage ready on load
                return change;
            }

            return this.handleStageInit(change);
        });
    }

    getPositionLeft(left) {
        const { appStore } = this.props,
            doorLeft = parseInt(left, 10),
            windowWidth = window.innerWidth / 2,
            adjust = this.getRandom(windowWidth - 50);

        let total = doorLeft - windowWidth + adjust;

        if (total > (appStore.sceneSize.w - (windowWidth * 1.5))) { // adjust * 1.5 for pointy bits on right of illustration illustration
           total = total - windowWidth;
        }

        if (total < windowWidth) {
            total = total + windowWidth;
        }

        return total;
    }

    getPositionTop(top) {
        const { appStore } = this.props,
            doorTop = parseInt(top, 10),
            windowHeight = window.innerHeight / 2,
            adjust = this.getRandom(windowHeight - 70);

        let total = doorTop - windowHeight + adjust;

        if (total > (appStore.sceneSize.h - windowHeight - 100)) {
            total = total - windowHeight;
        }

        if (total < windowHeight) {
            total = 0;
        }

        return total;
    }

    /**
     * Returns a number between 1 and max of -1 and -max
     * @param max
     * @returns {number}
     */
    getRandom(max) {
        const rand = Math.floor((Math.random() * max) + 1),
            bool = Math.random();

        return bool <= 0.5 ? -(rand) : rand;
    }

    getDefaultLeft() {
        const { appStore } = this.props;
        return appStore.sceneSize.w / 2;
    }

    getDefaultTop() {
        const { appStore } = this.props;
        return appStore.sceneSize.h / 2;
    }

    /**
     * Add the ability to zoom, and listen to zoom events
     */
    attachZoomer() {
        const { todaysPosition } = this.props.appStore,
            left = todaysPosition.hasOwnProperty('left') ? todaysPosition.left : this.getDefaultLeft(),
            top = todaysPosition.hasOwnProperty('top') ? todaysPosition.top : this.getDefaultTop(),
            x = this.getPositionLeft(left),
            y = this.getPositionTop(top),
            zoom = createPanZoom(this.zoomer, {
                maxZoom: 1.3,
                minZoom: 0.35,
                onTouch: function (e) {
                    return false; // tells the library to not preventDefault.
                }
            }),
            self = this;

        zoom.moveTo(-x, -y);
        this.slideStarted = true;

        zoom.on('panstart', function(e) {
            if (typeof self.bounds === 'undefined') {
                self.setBounds();
            }

            self.isMoving = true;
        });

        zoom.on('zoom', function(e) {
            self.setBounds();
            self.isMoving = true;
            self.zoomTime = new Date();

            setTimeout(function () {
                if (new Date() - self.zoomTime >= 1000) {
                    self.isMoving = false;
                }
            }, 1000);
        });

        zoom.on('panend', function(e) {
            self.isMoving = false;
        });

    }

    canSeeWhole() {
        const insideWidth = this.bounds.width < window.innerWidth,
            insideHeight = (this.bounds.height + 100) < window.innerHeight;

        return insideWidth && insideHeight;
    }


    setBounds() {
        this.bounds = this.zoomer.getBoundingClientRect();
    }

    /**
     * prevent zoom from killing the touch action
     * @param e
     */
    onTouch(e) {
        e.stopPropagation();
        e.preventDefault();
    }

    /**
     * On very first render, once we have a slider, we need tod go to today's scene
     * @param change
     * @returns {*}
     */
    handleStageInit(change) {
        const { appStore } = this.props;

        let todaysSlide = this.getTodaysScene(this.deals),
            timeOut = appStore.startShown ? 0 : 500;

        setTimeout(function() {
            appStore.triggerScene(todaysSlide);
        }, timeOut); // make sure we're last to act

        return change;
    }

    getDoorInView() {
        const { appStore } = this.props,
            doorLeft = parseInt(appStore.todaysPosition.left, 10),
            doorTop = parseInt(appStore.todaysPosition.top, 10);

        return doorLeft < window.innerWidth && doorTop < window.innerHeight;
    }

    /**
     * Update our slider's state
     * @param change
     * @returns {*}
     */
    handleGoToScene(change) {
        const { appStore } = this.props;

        if (this.sceneRefs.length < 1) {
            return change;
        }

        appStore.setCurrentScene(change.newValue);

        return change;
    }

    getTodaysScene(deals) {
        const { appStore } = this.props;

        let todaysDeal = null;

        if (deals && Array.isArray(deals)) {
            todaysDeal = deals.filter(deal => {
                return deal.IsToday === true;
            });
        }

        if (!todaysDeal || todaysDeal.length < 1) {
            return 0; // default to the first scene
        }

        return appStore.getSceneForDeal(todaysDeal[0].Date);
    }

    /**
     * Check if a deal with a particular date is in our array
     * @param deals
     * @param i
     */
    hasDealDate(deals, i) {
        return deals.find(o => o.Date === i);
    }

    /**
     * Populate all 24 advent dates for easy rendering
     * @param setDeals
     * @returns Array
     */
    populateEmptyDeals(setDeals) {
        if (typeof setDeals === 'undefined' || !setDeals) {
            setDeals = [];
        }

        let deals = setDeals.slice(),
            result = [],
            i;

        for (i = 1; i < 26; i = i + 1) {
            if (!this.hasDealDate(deals, i)) {
                result.push({Date: i, ID: 'empty-' + i});
            }
        }

        return deals.concat(result);
    }

    sortDeals() {
        let deal;

        for (deal in this.deals) {
            if (this.deals.hasOwnProperty(deal)){
                this.deals[deal].Order = this.doorOrder[this.deals[deal].Date - 1];
            }
        }
        this.deals = this.deals.sort((a, b) => a.Order - b.Order);
    }

    getDaysLeft() {
        const { appStore } = this.props;

        if (appStore.adventState !== 'before' || (appStore.adventCurrentDay && appStore.adventCurrentDay > 0)) {
            return false;
        }

        return <span className='stage-note'>
            <strong>{ Math.abs(appStore.adventCurrentDay) }</strong>
            { Math.abs(appStore.adventCurrentDay) > 1 ? ' days' : ' day' } to go!
        </span>;
    }

    /**
     * Scene rendering
     * @param i
     * @param appStore
     * @param sceneNum
     * @param isRedeem
     * @param renderImages
     * @param ready
     * @param scene
     * @returns {*}
     */
    renderScene(i, appStore, sceneNum, isRedeem, renderImages, ready, scene) {
        return <div className='stage-item' key={i}>
            {ready && <Scene
                ref={(scene) => {
                    this.sceneRefs[i] = scene;
                }}
                scene={scene}
                sceneNum={i}
                appStore={appStore}
                deals={this.deals}
            />}
        </div>;
    }

    renderTagline() {
        const { appStore } = this.props;

        if (appStore.adventState === 'before') {
            return <p className='stage-tagline'>
                We're still busy building the Advent Calendar, come back on December 1<sup>st</sup>
            </p>;
        }

        if (appStore.adventState === 'shut') {
            return <p className='stage-tagline'>Advent is over for the year!</p>;
        }

        if (appStore.adventState === 'over') {
            return <p className='stage-tagline'>
                The Wellington Advent Calendar has finished.
                Vouchers are valid until January 31, so enjoy!
            </p>;
        }


        if (appStore.adventState) {
            return <p className='stage-tagline'>
                Open a door each day to reveal treats from some of your favourite Wellington spots
            </p>;
        }

        return <p className='stage-tagline' />;
    }

    renderHeader() {
        const { appStore } = this.props;

        return <header className={this.getDaysLeft() ? 'stage-header stage-header--pending' : 'stage-header'}>
            <div className='stage-textgroup'>
                <h1 className={this.getDaysLeft() ? 'stage-headline' : 'stage-header sr-only'}>
                    <span className='stage-title'>Wellington</span>
                    <span className='stage-title stage-title--smaller'>Advent Calendar</span>
                </h1>
                {!!this.getDaysLeft() && this.renderTagline()}
                <div className='stage-remaining'>
                    {this.getDaysLeft()}
                </div>
            </div>
        </header>;
    }

    renderDoor(deal) {
        const { appStore } = this.props,
            opened = appStore.doorOpened.slice(),
            claimed = appStore.dealClaimed.slice();
        return <Door
            sceneNum={3}
            prefix='stagedoor'
            key={deal.ID}
            Deal={deal}
            HasOpened={opened.find(item => parseInt(item, 10) === parseInt(deal.ID, 10))}
            HasClaimed={claimed.find(item => parseInt(item, 10) === parseInt(deal.ID, 10))}
        />
    }

    render() {
        const { appStore, renderImages, isRedeem } = this.props;

        let ready = false;

        if (appStore.deals && appStore.state > 2) {
            this.deals = this.populateEmptyDeals(appStore.deals);
            this.sortDeals();
            ready = true;
        }

        const lastDeal = this.deals[this.deals.length - 1],
            hasXmasDay = lastDeal.Date === 25;

        return <div className={this.getDaysLeft() ? 'stage-wrap stage-wrap--pending' : 'stage-wrap'}>
            <div className='stage'>
                {this.renderHeader()}
                <main className='stage-main'>
                    {appStore.sceneDoors.map((scene, i) =>
                        this.renderScene(i, appStore, i, isRedeem, renderImages, ready, scene)
                    )}
                </main>
                {!this.getDaysLeft() && this.renderTagline()}
            </div>
            <div className='stagedoor-wrap'>
                { hasXmasDay && this.renderDoor(lastDeal) }
            </div>
        </div>;
    }
}
