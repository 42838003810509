import React, { Component } from 'react';
import { observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { CSSTransition } from 'react-transition-group';
import CountUp from 'react-countup';
import ReactGA from 'react-ga';
import './loading.scss';
import { LinearProgress } from '@material-ui/core';

let classNames = require('classnames'),
    Shake = require('shake.js');

@inject('appStore', 'routerStore')
@observer
export class Loading extends Component {

    @observable buttonClicked = false;

    loadTime = 0;
    loadTimeout = 20000; // 20 seconds
    loadIncrement = 500;
    loadTimer;

    _shakeObj;
    _onShake;

    componentDidMount() {
        this.loadTimer = setInterval(() => {
            this.loadCounter();
        }, this.loadIncrement);

        this._shakeObj = new Shake({
            threshold: 10, // optional shake strength threshold
            timeout: 1000 // optional, determines the frequency of event generation
        });

        this._shakeObj.start();
        this._onShake = this.onShake.bind(this);
        window.addEventListener('shake', this._onShake, false);
    }

    componentWillUnmount() {
        this.clearLoadTimer();
        window.removeEventListener('shake', this._onShake, false);
    }

    onShake() {
        if (this.showButton()) {
            this.onClick();
        }
    }

    loadCounter() {
        const { appStore } = this.props;
        this.loadTime += this.loadIncrement;
        if (this.loadTime >= this.loadTimeout) {
            this.clearLoadTimer();
            appStore.updateLoadingProgress(1, true);
        }
    }

    clearLoadTimer() {
        if (this.loadTimer) {
            clearInterval(this.loadTimer);
            this.loadTimer = false;
        }
    }

    /**
     * Loading screen finished transitioning in
     * Set app state to loading (1)
     */
    onEntered() {
        const { appStore } = this.props;

        appStore.setAppState(1);
    }

    /**
     * Remove the loading screen
     * Set app state
     * * if user has dismissed the start screen previously, or if
     * * there is a route present, proceed to app
     * * otherwise show start screen
     */
    onExited() {
        // track loading screen closed
        ReactGA.event({
            category: 'advent',
            action: 'intro_clicked',
            label: 'peek_inside',
            nonInteraction: true
        });

        this.showStage();
    }

    showStage() {
        const { appStore } = this.props;
        appStore.setAppState(7); // go to app
    }

    /**
     * 'Peek Inside' button finished transitioning in
     * Set app state to ready (3)
     */
    onLoadingButtonEntered() {
        const { appStore } = this.props;

        if (appStore.state < 3) {
            appStore.setAppState(3);
        }
    }

    /**
     * Loading completed
     * Set state to loaded (2)
     */
    onProgressComplete() {
        const { appStore, routerStore } = this.props;

        if (appStore.loadTo < 100) {
            return;
        }

        this.clearLoadTimer();

        // don't proceed if advent hasn't started yet.
        if (appStore.adventCurrentDay < 1) {
            return;
        }

        if (routerStore.location.pathname === '/') {
            if (appStore.state < 2) {
                appStore.setAppState(2); // show button, slight pause between loading completed and next screen
            }
        } else if (appStore.state < 4) {
            appStore.setAppState(4); // fade out loading screen
        }
    }

    /**
     * Transition the loading screen out
     * Set state to Loaded screen out (4)
     */
    onClick() {
        const { appStore } = this.props;

        this.buttonClicked = true;

        if (appStore.state > 3) {
            return;
        }

        setTimeout(function() {
            appStore.setAppState(4);
        }, 500); // prevent race condition with onLoadingButtonEntered + animation for image
    }

    /**
     * 'Peek Inside' button
     */
    getButton() {
        return <CSSTransition
            timeout={300}
            in
            classNames='pure-button-'
            onEntered={() => this.onLoadingButtonEntered()}
            appear>
            <button className='pure-button pure-button--default' onClick={() => this.onClick()} style={{position: 'relative', zIndex: '99999999'}}>
                 <span className='pure-button-inner'>
                    Explore
                 </span>
            </button>
        </CSSTransition>;
    }

    /**
     * When open or over (but not yet shut), return render value
     * On before and shut, return false
     * @returns {boolean}
     */
    showButton() {
        const { appStore, routerStore } = this.props,
            render = appStore.state >= 2 && routerStore.location.pathname === '/';

        if (appStore.adventState === 'before' || appStore.adventState === 'shut') {
            return false;
        }

        return render;
    }

    getContent() {
        const { appStore } = this.props;

        if (appStore.adventState === 'before') {
            return <p className='loading-tagline'>
                Hang on, you... it's not the 1st of December yet!
            </p>;
        }

        if (appStore.adventState === 'shut') {
            return <p className='loading-tagline'>Advent is over for the year!</p>;
        }

        if (appStore.adventState === 'over') {
            return <p className='loading-tagline'>
                The Wellington Advent Calendar has finished.
                Vouchers are valid until 28 February 2020, so enjoy!
            </p>;
        }

        if (appStore.adventState) {
            return <p className='loading-tagline'>
                24 festive treats that will blow you away
            </p>;
        }

        return <p className='loading-tagline' />;
    }

    /**
     * load progress indicator
     */
    getProgressIndicator() {
        const { appStore } = this.props;

        if (!appStore.adventCurrentDay || appStore.adventCurrentDay < 1) {
            return <></>;
        }

        return  <CountUp
            start={appStore.loadFrom}
            end={appStore.loadTo}
            className='loading-progress pure-button pure-button--faux'
            duration={0.3}
            decimals={0}
            onEnd={() => this.onProgressComplete()}
        >
            {({ countUpRef, update }) => {
                const percentage = countUpRef.current ? countUpRef.current.textContent : 0;

                return <>
                    <span className="loading-number">
                        <span ref={countUpRef}/>
                        <span>% loaded </span>
                    </span>
                    {countUpRef.current && <div className="loading-splash">
                        <LinearProgress
                        variant="determinate"
                        value={parseInt(percentage, 10)}
                        className="loading-splashindicator"
                        color="primary"
                        />
                    </div>
                    }
                </>;
            }}
        </CountUp>;
    }

    getDaysLeft() {
        const { appStore } = this.props;

        if (appStore.adventState !== 'before' || (appStore.adventCurrentDay && appStore.adventCurrentDay > 0)) {
            return false;
        }

        return <span className='loading-note pure-button pure-button--faux'>
            <strong>{ Math.abs(appStore.adventCurrentDay) }</strong>
            { Math.abs(appStore.adventCurrentDay) > 1 ? ' days' : ' day' } to go!
        </span>;
    }

    render() {
        const { appStore } = this.props,
            render = appStore.state < 5,
            renderBg = appStore.state < 2,
            transition = appStore.state < 4,
            classes = classNames({
                'loading': true,
                'loading--larger': !appStore.isSlowLatency,
                'loading--complete': appStore.state > 2
            });

        return <div className='loading-transition'>
            {render && <CSSTransition
                in={transition}
                timeout={{enter: 3000, exit: 1000}}
                classNames='loading-'
                onEntered={() => this.onEntered()}
                onExited={() => this.onExited()}
                appear>
                <div className={classes}>
                    {this.getProgressIndicator()}
                    <div className='loading-container'>
                        <div className='loading-content'>
                            <div>
                                <div className='loading-textgroup'>
                                    <h1 className='loading-headline'>
                                        <span className='loading-suptitle'>10th Edition</span>
                                        <span className='loading-headlinewrap'>
                                            <span className='loading-title'>
                                                2023
                                            </span>
                                        </span>
                                        <span className='loading-subtitle'>Wellington Advent Calendar</span>
                                    </h1>
                                    {this.getContent()}
                                    <div className='loading-remaining'>
                                        {this.getDaysLeft()}
                                    </div>
                                </div>
                                <div className={this.buttonClicked ? 'loading-imagewrap loading-imagewrap--clicked' : 'loading-imagewrap'}>
                                    <img
                                        className='loading-img'
                                        src={appStore.titleImage.src}
                                        alt=''
                                        role='presentation'
                                    />
                                    <div className='loading-controls'>
                                        {this.showButton() && this.getButton()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>}
            {renderBg && <div className='loading-background' />}
        </div>;
    }

}
