import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import { RedeemStore } from './redeem.store';
import { RedeemComponent } from './redeem.component';
import { RedeemResultComponent } from './redeemresult.component';
import RedeemForm from './redeem.form';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import ModalLoading from '../shared/modalloading/modalloading';

import './redeem.scss';

@observer
export class RedeemProvider extends Component {

    redeemStore;
    hasError = false;

    constructor(props) {
        super(props);

        this.redeemStore = new RedeemStore();
        this.setToken();

        if (this.redeemStore.Token) {
            this.redeemStore.initializeDeal();

            this.redeemForm = new RedeemForm();
            this.redeemForm.setURL(this.redeemStore.Link);
            this.redeemForm.setStore(this.redeemStore);
        } else {
            this.hasError = true;
        }
    }

    setToken() {
        let tokenID = this.props.match.params['token'],
            tokenArray = tokenID.split('-'),
            token = tokenArray[0],
            dealID = tokenArray[1];

        this.redeemStore.Link = this.props.match.params['token'];
        this.redeemStore.DealID = parseInt(dealID, 10);
        this.redeemStore.Token = token;
    }

    getComponent() {
        const props = this.props.match.params,
            result = props['result'];

        if (this.redeemStore.loading) {
            return <ModalLoading key='redeemloading' />;
        } else if (result && (result === 'success' || result === 'error')) {
            return <RedeemResultComponent key='redeemresult' hasError={result === 'error'} />;
        }

        return <RedeemComponent key='redeemform' />;
    }

    render() {
        return <div className='modal-wrapper'>
            <div className='modal-content modal-content--flush'>
                <div className='modal-inner'>
                    <Provider
                        dealStore={this.redeemStore}
                        redeemForm={this.redeemForm}
                        redeemStore={this.redeemStore}
                    >
                        <div>
                            <ReactCSSTransitionReplace
                                transitionName='modal-animate'
                                transitionEnterTimeout={600}
                                transitionLeaveTimeout={300}
                                component='div'
                                childComponent='div'>
                                {this.getComponent()}
                            </ReactCSSTransitionReplace>
                        </div>
                    </Provider>
                </div>
            </div>
        </div>;
    }

}
