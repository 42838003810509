import React, { Component } from 'react';
import { CircularProgress } from '@material-ui/core';
import { RouteHeadComponent } from '../../shared/routehead.component';

export default class ModalLoading extends Component {

    constructor(props) {
        super(props);
        document.body.classList.add('modal-isloading');
    }

    componentWillUnmount() {
        document.body.classList.remove('modal-isloading');
    }

    render() {
        return <div className='modal-loading'>
            <RouteHeadComponent />
            <CircularProgress
                color="secondary"
                size={100}
                thickness={5}
                className='loader' />
        </div>;
    }

}
