import React, { Component } from 'react';
import { observer, Provider } from 'mobx-react';
import { ContentStore } from './content.store';
import { ContentComponent } from './content.component';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import ModalLoading from '../shared/modalloading/modalloading';

@observer
export class ContentProvider extends Component {

    contentStore;

    constructor(props) {
        super(props);
        this.contentStore = new ContentStore();
        this.contentStore.Link = this.getURL(this.props.match.params);

        this.contentStore.initializeContent();
    }

    /**
     * Construct the query url from our router params
     * eg :id1/:id2/:id3:id4
     */
    getURL(params) {
        let key,
            url = '',
            count = 0;

        for (key in params) {
            if (params.hasOwnProperty(key)) {
                url += count > 0 ? '/' : '';
                url += params[key];
                count++;
            }
        }

        return url;
    }

    getComponent() {
        if (this.contentStore.loading) {
            return <ModalLoading key='contentloading' />;
        }

        return <ContentComponent key='contentpage' />;
    }

    render() {
        return <div className='modal-wrapper'>
            <div className='modal-content'>
                <div className='modal-inner'>
                    <Provider contentStore={this.contentStore}>
                        <div>
                            <ReactCSSTransitionReplace
                                transitionName='modal-animate'
                                transitionEnterTimeout={600}
                                transitionLeaveTimeout={300}
                                component='div'
                                childComponent='div'>
                                {this.getComponent()}
                            </ReactCSSTransitionReplace>
                        </div>
                    </Provider>
                </div>
            </div>
        </div>;
    }

}
