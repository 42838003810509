import React, { Component } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export class CheckboxIconUnchecked extends Component {

    render() {
        return <SvgIcon viewBox='0 0 24 24'>
            <rect stroke='#52392e' x='3.5' y='3.5' width='17' height='17' />
        </SvgIcon>;
    }

}
