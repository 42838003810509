import React, { Component } from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { intercept, observable, autorun } from 'mobx';
import { ClaimStore } from './claim.store';
import { ClaimDealComponent } from './claimdeal.component';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import ModalLoading from '../shared/modalloading/modalloading';
import ClaimForm from './claim.form';
import './claim.scss';
import './claimmodal.scss';
import '../_deal/deal.scss';

@inject('appStore', 'routerStore')
@observer
export class ClaimProvider extends Component {

    claimStore; // : ClaimStore;

    @observable deal;

    constructor(props) {
        super(props);
        const { appStore, routerStore } = props,
            self = this;

        this.claimStore = new ClaimStore();
        this.claimForm = new ClaimForm();
        this.claimForm.setStore(this.claimStore);

        this.disposeClaimForm = autorun(() => {
            if (appStore.userData) {
                this.claimForm.update(appStore.userData);
            }
        });

        this.disposeDeals = intercept(appStore, 'deals', (change) => {
            if (change.newValue) {
                if (change.newValue.length < 1) {
                    routerStore.history.push('/404');
                }
                this.getDeal(change.newValue);
            }
            return change;
        });

        // set as empty function in case we need to unmount early
        this.disposeDealTheme = () => void 0;

        this.setDeal(props);
    }

    addModalClasses(appStore) {
        let theme = 'modaltheme--' + appStore.currentDeal.ThemeColour,
            textTheme = 'modaltheme--text' + appStore.currentDeal.TextShade;

        document.body.classList.add(theme);
        document.body.classList.add(textTheme);
    }

    setDeal(props) {
        const { appStore } = props;

        this.claimStore.Link = props.match.params['a'];
        this.claimForm.setURL(this.claimStore.Link);
        this.setContext(props);

        if (appStore.deals.length) {
            this.getDeal(appStore.deals);
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.a !== this.props.match.params.a) {
            this.removeTheme();
            this.setDeal(this.props);
        }
    }

    setContext(props) {
        const match = props.match,
            params = match.params,
            result = params['result'];

        let urlParams = match.path,
            context = urlParams.replace('/:a', "").replace('/:result', "").replace(/\//g, '');

        if (result) {
            context = 'result'
        }

        this.claimStore.setContext(context);

        if (context !== 'deal') {
            this.claimStore.openForm(true);
        }
    }

    componentWillUnmount() {
        this.removeTheme();
        this.disposeClaimForm();
        this.disposeDeals();
        this.disposeDealTheme();
    }

    removeTheme() {
        document.body.classList.forEach(className => {
            if (className.startsWith('modaltheme--')) {
                document.body.classList.remove(className);
            }
        });
    }

    getDeal(deals) {
        const { routerStore, appStore, match } = this.props,
            self = this,
            URLSegment = this.getURL(match.params);

        deals = deals.slice();

        if (!deals.length) {
            return;
        }
        this.deal = this.getDealByUrlSegment(deals, URLSegment);
        this.removeTheme();

        if (this.deal) {
            appStore.setDeal(this.deal);
            this.disposeDealTheme = autorun(() => {
                if (appStore.currentDeal) {
                    this.addModalClasses(appStore);
                }
            });
            return;
        }

        if (match.params.a) {
            let param = parseInt(this.props.match.params.a, 10);
            this.handleLocked(deals, param);
            this.disposeDealTheme = autorun(() => {
                this.addModalClasses(appStore);
            });
        } else {
            routerStore.history.push('/404');
        }
    }

    handleLocked(deals, param) {
        const { routerStore, appStore } = this.props;

        if (!Number.isInteger(param) || param > 24 || param < 0) {
            routerStore.history.push('/404');
        }

        this.deal = this.getDealByDate(deals, param);
        if (this.deal) {
            routerStore.history.push('/deal/' + this.deal.URLSegment);
            return;
        }

        this.setLockedDeal(param, deals);
    }

    setLockedDeal(Day, deals) {
        const { appStore } = this.props;

        deals = deals.filter(deal => {
            return deal.LinkOnly === false;
        });

        let lastDeal = deals[deals.length - 1],
            prev = lastDeal.Date > Day ? deals[deals.length - 2] : lastDeal,
            dateNice = '',
            date = new Date(appStore.startDate),
            when = 'tomorrow.',
            difference = Day - prev.Date,
            color = this.getColor();

        date.setDate(date.getDate() + (Day - 1));
        dateNice = this.getDay(date.getDay()) + ' ' + date.getDate() +  ' ' + this.getMonth(date.getMonth());

        if (difference > 1) {
            when = 'in ' +  difference + ' days time.';
        }

        this.deal = {
            Date: Day,
            DateNice: dateNice,
            ID: 'empty-' + Day,
            PrevDeal: prev,
            NextDeal: false,
            IsLocked: true,
            ThemeColour: color,
            TextShade: 'dark',
            DateType: 'date',
            ImageURL: false,
            LockedText: 'This door is not open yet. It will be available ' + when,
            Details: 'Keep swiping to see previous treats.'
        };

        appStore.setDeal(this.deal);
    }

    getDay(num) {
        let days =['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return days[num];
    }

    getMonth(num) {
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return months[num];
    }

    getColor() {
        const colors = ['coral','peach','apricot','bagel','shell','mint','avocado','sea-green'],
            random = Math.floor(Math.random() * colors.length);

        return colors[random];
    }

    /**
     * get a deal by its URLSegment
     */
    getDealByDate(deals, Date) {
        return deals.find(
            item => item.Date === Date
        );
    }

    /**
     * get a deal by its URLSegment
     */
    getDealByUrlSegment(deals, URLSegment) {
        return deals.find(
            item => item.URLSegment === URLSegment
        );
    }

    /**
     * Construct the query url from our router params
     * eg :id1/:id2/:id3:id4
     */
    getURL(params) {
        let key,
            url = '',
            count = 0;

        for (key in params) {
            if (params.hasOwnProperty(key)) {
                url += count > 0 ? '/' : '';
                url += params[key];
                count++;
            }
        }

        return url;
    }

    getComponent() {
        const match = this.props.match,
            params = match.params,
            result = params['result'];

        if (!this.deal) {
            return <ModalLoading key='claimloading' />;
        }

        return <ClaimDealComponent key='claimdeal' result={result} deal={this.deal} />;
    }

    render() {
        return <div className='modal-wrapper modal-wrapper--deal'>
            <div className='modal-content'>
                <div className='modal-inner'>
                    <Provider claimForm={this.claimForm} claimStore={this.claimStore}>
                        <div className='modal-body'>
                            <ReactCSSTransitionReplace
                                transitionName='modal-animate'
                                transitionEnterTimeout={600}
                                transitionLeaveTimeout={300}
                                component='div'
                                childComponent='div'>
                                {this.getComponent()}
                            </ReactCSSTransitionReplace>
                        </div>
                    </Provider>
                </div>
            </div>
        </div>;
    }

}
