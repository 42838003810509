import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import {
    Input,
    InputLabel,
    MenuItem,
    FormControl,
    FormControlLabel,
    FormHelperText
} from '@material-ui/core';
import { RouteHeadComponent } from '../shared/routehead.component';
import ClaimForm from './claim.form';
import { CheckboxIconChecked } from '../shared/checkbox/checkboxicon.checked';
import { CheckboxIconUnchecked } from '../shared/checkbox/checkboxicon.unchecked';
import ReactModal from 'react-modal';
import { ClaimResultComponent } from "./claimresult.component";
import ModalLoading from "../shared/modalloading/modalloading";

ReactModal.setAppElement('#root');

@inject('appStore', 'routerStore', 'claimStore', 'claimForm')
@observer
export class ClaimComponent extends Component {

    modalStyles = {
        content: {
            top        : 'auto',
            left       : '0',
            right      : '0',
            bottom     : '0',
            marginRight: '0'
        }
    };

    getInput(fieldName) {
        const { claimForm } = this.props,
            hasError = claimForm.errors()[fieldName] !== null;

        return <FormControl
            fullWidth
            className='field'
            error={hasError}
        >
            <InputLabel
                htmlFor={claimForm.$(fieldName).id}
                className='field-textlabel'>
                {claimForm.$(fieldName).label}
            </InputLabel>
            <Input
                error={hasError}
                className='field-text'
                {...claimForm.$(fieldName).bind()}
            />

            {claimForm.errors()[fieldName] &&
                <FormHelperText>
                    {claimForm.errors()[fieldName]}
                </FormHelperText>}
        </FormControl>;
    }

    getSelect(fieldName, items) {
        const { claimForm } = this.props,
            hasError = claimForm.errors()[fieldName] !== null;

        return <FormControl
            fullWidth
            className='field'
            error={hasError}
        >
            <InputLabel htmlFor={claimForm.$(fieldName).id}>
                {claimForm.$(fieldName).label}
            </InputLabel>

            <Select
                error={hasError}
                className='field-text'
                {...claimForm.$(fieldName).bind()}
            >
                <MenuItem value=''>
                    <em>None</em>
                </MenuItem>

                {items.map((key, index) =>
                    <MenuItem key={index} value={key}>
                        {key}
                    </MenuItem>
                )}
            </Select>

            {claimForm.errors()[fieldName] &&
                <FormHelperText>
                    {claimForm.errors()[fieldName]}
                </FormHelperText>}
        </FormControl>;
    }

    getCheckbox(fieldName) {
        const { claimForm } = this.props;

        return <Checkbox
            className='field-checkbox'
            icon={<CheckboxIconUnchecked />}
            checkedIcon={<CheckboxIconChecked />}
            classes={{
                checked: 'checkbox--ischecked',
                disabled: 'checkbox--isdisabled'
            }}
            onChange={(event, checked) => this.handleChange(event, checked, fieldName, claimForm)}
        />;
    }

    /**
     * Manually store the state of the checkboxes
     * @param event
     * @param checked
     * @param fieldName
     * @param claimForm
     */
    handleChange(event, checked, fieldName, claimForm) {
        claimForm.checkboxes[fieldName] = checked;
    }

    getForm(isClaimed, termsLink) {
        const { claimForm, deal } = this.props,
            isDraw = deal.DealAction === 'draw';

        return <form onSubmit={claimForm.onSubmit}>
            <div className='pure-g'>
                <div className='pure-u-1 pure-u-md-1-2 field-wrap--left'>
                    {this.getInput('FirstName')}
                </div>
                <div className='pure-u-1 pure-u-md-1-2 field-wrap--right '>
                    {this.getInput('LastName')}
                </div>
            </div>

            {this.getInput('Email')}

            <div className='field-group'>
                <FormControl
                    fullWidth
                    className='field field--checkboxwrap'
                    error={claimForm.errors().Terms}
                >
                    <FormControlLabel
                        control={this.getCheckbox('Terms')}
                        className='field-checkboxlabel'
                        label={
                            <span>
                                I have read and accept the{' '}
                                <a href={termsLink} target='_blank' rel='noopener'>
                                    terms and conditions
                                </a>{' '}
                            </span>
                        }
                    />
                    {claimForm.errors().Terms &&
                        <FormHelperText>
                            The terms and conditions must be accepted
                        </FormHelperText>}
                </FormControl>
            </div>

            <div className='form-actions'>
                <button
                    type='submit'
                    className='pure-button pure-button--default'
                    onClick={e => claimForm.onSubmit(e)}>
                    <span className='pure-button-inner'>
                        {!isDraw && <>
                            {isClaimed ? 'Resend voucher' : 'Email voucher'}
                        </>}
                        {isDraw && <>
                            {isClaimed ? 'Resend my draw entry' : 'Enter the draw'}
                        </>}
                    </span>
                </button>
            </div>
        </form>;
    }

    onClick(e) {
        const { claimStore } = this.props
        claimStore.setContext('deal');
        claimStore.openForm(false);
    }

    getModal() {
        const {claimForm, claimStore } = this.props;

        return <ReactModal
            overlayClassName='claimmodal-overlay'
            isOpen={claimStore.formOpen}
            className='claimmodal modaltheme--textdark'
            style={this.modalStyles}
            shouldCloseOnOverlayClick={true}
            onRequestClose={e => this.onClick(e)}
            contentLabel='Claim this deal'
            closeTimeoutMS={200}
        >
            <div className='modal-closewrap'>
                <button className='modal-close' onClick={e => this.onClick(e)}>
                    <span className='sr-only'>Close</span>
                </button>
            </div>
            <div className='modal-inner claimmodal-inner'>
                <div className='modal-body claimmodal-body'>
                    <div className='claimmodal-container container'>
                        <div className='pure-u-1'>
                            <div className='claimmodal-content'>
                                {claimStore.loading && <ModalLoading key='claimloading'/>}
                                {!claimStore.loading && this.getModalContent()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {(claimForm.error || claimStore.errorMessage) && this.renderErrors()}
        </ReactModal>;
    }

    getModalContent() {
        const { appStore, claimStore, deal, result } = this.props,
            termsLink = appStore.termsLink,
            claimedList = appStore.dealClaimed.slice(),
            isClaimed = claimedList.find(
                item => parseInt(item, 10) === parseInt(deal.ID, 10)
            ),
            adventOpen = appStore.adventState === 'open',
            hasResult = result && (result === 'success' || result === 'error'),
            isDraw = deal.DealAction === 'draw';

        return <>
            {hasResult && <ClaimResultComponent key='claimresult' hasError={result === 'error'} deal={deal} claimStore={claimStore}/>}

            {!hasResult && <div className='claimmodal-form form-group'>
                <h1 className='claim-title' id='heading'>
                    Merry Christmas from WellingtonNZ
                </h1>
                {adventOpen && <>
                    <h2 className='claim-subtitle'>
                        Enter your details
                        {!isDraw && <> and we will email your voucher</>}
                        {isDraw && <> to go in the draw to win the ultimate advent experience</>}
                    </h2>
                    {this.getForm(isClaimed, termsLink)}
                </>}

                {!adventOpen && <>
                    <h2 className='claim-subtitle'>
                        You can no longer claim new deals
                    </h2>
                    <button className='pure-button pure-button--default' onClick={e => this.onClick(e)}>
                        <span className='pure-button-inner'>
                            Close
                        </span>
                    </button>
                </>}
            </div>}
        </>;
    }

    renderErrors() {
        const {  claimForm, claimStore } = this.props;

        return <div className='alert-wrapper'>
            {claimForm.error &&
            <p className='alert alert--error'>
                {claimForm.error}
            </p>}
            {claimStore.errorMessage &&
            <p className='alert alert--error'>
                {claimStore.errorMessage}
            </p>}
        </div>;
    }

    /**
     * Note: it should be impossible to reach this view when the advent is not open,
     * via current interfaces, but we should prevent it in case it is linked to via
     * another means at a later date.
     */
    render() {
        const { deal } = this.props;

        let title = deal && deal.Title
            ? 'Claim: ' + deal.Title
            : 'Claim: Deal not found';

        return <>
            <RouteHeadComponent
                currentTitle={title}
                currentPage={deal}
                showModalClose={false}
            />
            {this.getModal()}
        </>;
    }

}
